import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-md/lib/Avatars';
import FontIcon from 'react-md/lib/FontIcons';
import AccessibleFakeButton from 'react-md/lib/Helpers/AccessibleFakeButton';
import IconSeparator from 'react-md/lib/Helpers/IconSeparator';
import DropdownMenu from 'react-md/lib/Menus/DropdownMenu';

import './account-menu.css';

class AccountMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { simplifiedMenu, image, name } = this.props;
    return(
      <DropdownMenu className="container-account-menu"
        id={ `${!simplifiedMenu ? 'smart-' : ''}avatar-dropdown-menu` }
        menuItems={ ['Account', { divider: true }, 'Log out'] }
        anchor={{
          x: DropdownMenu.HorizontalAnchors.CENTER,
          y: DropdownMenu.VerticalAnchors.OVERLAP,
        }}
        position={ DropdownMenu.Positions.TOP_LEFT }
        animationPosition="below"
        sameWidth
        simplifiedMenu={ simplifiedMenu }
        onClick={ (value, index, proxy) => this._syncStateSelect(value, index, proxy) }>
        <AccessibleFakeButton
          component={ IconSeparator }
          iconBefore
          label={
            <IconSeparator label={ name }>
              <FontIcon>arrow_drop_down</FontIcon>
            </IconSeparator>
          }>
          <Avatar src={ image }/>
        </AccessibleFakeButton>
      </DropdownMenu>
    );
  }

  _syncStateSelect = (value, index, proxy) => {
    debugger
    console.log(value + index + proxy);
  }
}

AccountMenu.propTypes = {
  simplifiedMenu: PropTypes.bool,
};

export default AccountMenu;
