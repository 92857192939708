import React from "react";
import "./styles/index.scss";
import appointIcon from "../../../../assets/icons/navigo-appoint-icon.svg";
import serviceIcon from "../../../../assets/icons/navigo-service-icon.svg";
import NavigoAppointment from "./components/navigo-appointments";
import NavigoServices from "./components/navigo-services";
import NavigoAPIServices from "./services/navigoAPIServices";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
class NavigoDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: 1,
    };
    this.navigoAPIService = new NavigoAPIServices();
  }
  handleLayoutChange = () => {
    this.setState({ layout: 1 });
  };
  handleSelectLayout = (type) => {
    this.setState({ layout: type });
  };
  handleFileUpload = (e, onSuccess, onError) => {
    const data = new FormData();
    data.append("file", e.target.files[0], "filename");
    this.navigoAPIService.uploadImg(
      data,
      (res) => {
        onSuccess(res);
      },
      (err) => {
        onError();
        console.log("error in uploading img", err);
      }
    );
  };

  render() {
    const { layout } = this.state;
    return (
      <div className="navigo-dashboard-container">
        {layout === 1 && (
          <div className="navigo-dashboard-container-layout-1">
            <h1 className="navigo-layout-1-main-heading">Navigo</h1>
            <p className="navigo-layout-1-main-desc">
              Manage appointments, types & their assignments from here
            </p>
            <div className="navigo-layout-1-services-options">
              <div
                className="navigo-layout-1-services-option"
                onClick={() => {
                  this.handleSelectLayout(2);
                }}
              >
                <img src={appointIcon} />
                <p className="navigo-layout-1-services-option-heading">
                  Appointments
                </p>
                <p className="navigo-layout-1-services-option-desc">
                  See current bookings and their statuses
                </p>
              </div>
              <div
                className="navigo-layout-1-services-option"
                onClick={() => {
                  this.handleSelectLayout(3);
                }}
              >
                <img src={serviceIcon} />
                <p className="navigo-layout-1-services-option-heading">
                  Services
                </p>
                <p className="navigo-layout-1-services-option-desc">
                  Manage available services types & calendars
                </p>
              </div>
            </div>
          </div>
        )}
        {layout === 2 && (
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <NavigoAppointment
              changeLayout={this.handleLayoutChange}
              uploadFile={this.handleFileUpload}
            />
          </MuiPickersUtilsProvider>
        )}
        {layout === 3 && (
          <NavigoServices
            changeLayout={this.handleLayoutChange}
            uploadFile={this.handleFileUpload}
          />
        )}
      </div>
    );
  }
}

export function GetStringSlot(slot) {
  let hours = Math.floor(slot / 60);
  let minutes = slot % 60;
  let hourString = hours < 10 ? "0" + hours : hours;
  let minString = minutes < 10 ? "0" + minutes : minutes;
  return hourString + ":" + minString;
}

export default NavigoDashboard;
