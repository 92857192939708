export const errorMessageHandler = (
  err,
  defaultMsg = "Something went wrong."
) => {
  let errMsg = defaultMsg;
  let valAssigned = false;
  try {
    if (err) {
      let msgArr = JSON.parse(err);

      if (msgArr.message) {
        errMsg = msgArr.message;
        let newMsg = JSON.parse(errMsg);
        if (newMsg && newMsg.message) {
          errMsg = newMsg.message;
        }
      }
    }
  } catch (error) {
    if (!valAssigned && err?.message) {
      errMsg = err.message;
    }
  }
  return errMsg;
};
