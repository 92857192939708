import AuthenticationService from "service/auth/AuthenticationService";
import Helper from "util/Helper";
import newAPIService from "./newAPIService";

class ApiService {
  get(url, onResponse, onError1) {
    return newAPIService.get(url, onResponse, onError1);
  }

  getAgain(url, onResponse, onError) {
    return newAPIService.getAgain(url, onResponse, onError);
  }
  getImage(url, onResponse, onError) {
    return newAPIService.getImage(url, onResponse, onError);
  }

  post(url, params, onResponse, onError1) {
    return newAPIService.post(url, params, onResponse, onError1);
  }
  postAgain(url, params, onResponse, onError) {
    return newAPIService.postAgain(url, params, onResponse, onError);
  }
  postv2(url, params, onResponse, onError) {
    return newAPIService.postv2(url, params, onResponse, onError);
  }

  postv3(url, params, onResponse, onError) {
    return newAPIService.postv3(url, params, onResponse, onError);
  }

  postWithFormData(url, params, onResponse, onError) {
    return newAPIService.postWithFormData(url, params, onResponse, onError);
  }

  patchtWithFormData(url, params, onResponse, onError) {
    return newAPIService.patchtWithFormData(url, params, onResponse, onError);
  }

  putWithFormData(url, params, onResponse, onError) {
    return newAPIService.putWithFormData(url, params, onResponse, onError);
  }

  put(url, params, onResponse, onError) {
    return newAPIService.put(url, params, onResponse, onError);
  }

  patch(url, params, onResponse, onError) {
    return newAPIService.patch(url, params, onResponse, onError);
  }

  delete(url, onResponse, onError) {
    return newAPIService.delete(url, onResponse, onError);
  }

  downloadCSV(url, fileName, onResponse, onError) {
    return newAPIService.downloadCSV(url, fileName, onResponse, onError);
  }
  getWithOptionalHeaders(url, header, onResponse, onError) {
    return newAPIService.getWithOptionalHeaders(
      url,
      header,
      onResponse,
      onError
    );
  }
  putWithOptionalHeaders(url, params, header, onResponse, onError) {
    return newAPIService.putWithOptionalHeaders(
      url,
      params,
      header,
      onResponse,
      onError
    );
  }
  downloadCSVwithOptionalHeader(url, fileName, header, onResponse, onError) {
    return newAPIService.downloadCSVwithOptionalHeader(
      url,
      fileName,
      header,
      onResponse,
      onError
    );
  }
  postWithOptionalHeaders(url, params, headers, onResponse, onError1) {
    return newAPIService.postWithOptionalHeaders(
      url,
      params,
      headers,
      onResponse,
      onError1
    );
  }
  postWithFormDataDownload(url, params, onResponse, onError) {
    return newAPIService.postWithFormDataDownload(
      url,
      params,
      onResponse,
      onError
    );
  }
}

export default ApiService;
