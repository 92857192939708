const nonCorporateClients = [
  "01XXDC",
  "03XXAZ",
  "03XXHH",
  "01XXZH",
  "01XXTP",
  "03XXCC",
  "02XXAB",
  "02XXAW",
  "04XXMB",
];
export const checkCorporate = (clientCode) => {
  console.log("aisisisisis", clientCode);
  let clientIndex = nonCorporateClients.findIndex((e) => e === clientCode);
  if (clientIndex > -1) {
    return false;
  } else {
    return true;
  }
};
