import AuthenticationService from 'service/auth/AuthenticationService';
import EnvironmentService from 'service/environment/EnvironmentService';
import APIConfig from 'service/network/APIConfig';
import APIService from 'service/network/APIService';

class ReportingService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(reporting, onResponse, onError) {
    let url = APIConfig.reporting.new;

    return this.apiService.post(url, reporting, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.reporting.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.reporting.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, reporting, onResponse, onError) {
    let url = APIConfig.reporting.update.format(id);

    return this.apiService.put(url, reporting, onResponse, onError);
  }

  getByPatient(patientId, dueDate, onResponse, onError) {
    let url = APIConfig.reporting.getByPatient.format(patientId, dueDate);

    return this.apiService.get(url, onResponse, onError);
  }
}

export default ReportingService;
