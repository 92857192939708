import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class ChiefComplaintService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(chiefComplaint, onResponse, onError) {
    let url = APIConfig.chiefComplaint.new;

    return this.apiService.post(url, chiefComplaint, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.chiefComplaint.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.chiefComplaint.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, chiefComplaint, onResponse, onError) {
    let url = APIConfig.chiefComplaint.update.format(id);

    return this.apiService.put(url, chiefComplaint, onResponse, onError);
  }

  getNext(patientid, pagesize, pageno, onResponse, onError) {
    let url = APIConfig.tracking.chiefComplaint.getNext.format(
      patientid,
      pagesize,
      pageno
    );

    return this.apiService.get(url, onResponse, onError);
  }

  getChiefComplaintsCount(patientId, onResponse, onError) {
    let url = APIConfig.tracking.chiefComplaint.get.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }
}

export default ChiefComplaintService;
