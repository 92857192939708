import ContentLoader from "react-content-loader";
import React from "react";
export default function GlobalSkeletonLoader(props) {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={80}
      viewBox="0 0 400 80"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="8" rx="3" ry="3" width="380" height="6" />
      <rect x="0" y="26" rx="3" ry="3" width="360" height="6" />
      <rect x="0" y="42" rx="3" ry="3" width="340" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="320" height="6" />
    </ContentLoader>
  );
}
