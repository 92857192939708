import React, { useState, useEffect } from "react";
import { Dialog } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EventService from "service/event/EventService";
import DocumentService from "../documents/services/DocumentService";
import { Button } from "react-md";
import "./style/index.css";
import FileUpload from "react-md/lib/FileInputs/FileUpload";
import SendEmailComponent from "./components/SendEmailComponent";
import SendSMSComponent from "./components/SendSMSComponent";

function SendDocuments(props) {
  const documentService = new DocumentService();
  useEffect(() => {}, []);

  const [sendDialog, setSendDialog] = useState(props.sendDialog || false);

  const [sendDialogContainer, setsendDialogContainer] = useState(
    props.container !== undefined ? props.container : ""
  );

  const [emailMessage, setEmailMessage] = useState(
    props.emailMessage !== undefined ? props.emailMessage : ""
  );

  const [patient, setPatient] = useState(props.patient);

  const [document, setDocument] = useState(props.document);
  // fileName publicUrl

  const _handleLoad = (uploadedFile, uploadedData) => {
    fetch(uploadedData)
      .then((response) => response.blob())
      .then((blob) => {
        addImage(blob, uploadedFile);
      });
  };

  const addImage = (blob, uploadedFile) => {
    let onResponse = (data) => {
      setDocument({
        publicUrl: data.publicUrl,
        fileName: uploadedFile.name,
      });
    };

    let onError = (error) => {
      EventService.emit("showError", {
        message: error.message,
      });
    };

    let imageForm = new FormData();
    imageForm.append("file", blob);
    imageForm.append("fileName", "nameffile");
    imageForm.append("patientId", patient.id);
    documentService.addImageNew(imageForm, onResponse, onError);
  };

  return (
    <Dialog open={sendDialog} className="send-documents-container">
      {sendDialogContainer === "" && (
        <Row className="send-documents-container-first-row">
          <label className="send-documents-heading">
            Patient Communication
          </label>
          <Button
            className="close-btn-send-documents"
            onClick={() => {
              props.closeSendDocument();
            }}
            icon
          >
            close
          </Button>
        </Row>
      )}

      {sendDialogContainer === "" && (
        <Row className="send-doc-option-container" xs={12} sm={12} md={12}>
          <Col
            xs={12}
            sm={6}
            md={6}
            onClick={() => {
              setsendDialogContainer("email");
            }}
          >
            <Button className="send-doc-btn">Email</Button>
          </Col>
          {/* <Col
            xs={12}
            sm={12}
            md={12}
            onClick={() => {
              // setsendDialogContainer("whatsapp");
            }}
          >
            <Button style={{ background: "gray" }} className="send-doc-btn">
              Whatsapp
            </Button>
          </Col> */}
          <Col
            xs={12}
            sm={6}
            md={6}
            onClick={() => {
              setsendDialogContainer("sms");
            }}
          >
            <Button className="send-doc-btn">SMS</Button>
          </Col>
        </Row>
      )}

      {sendDialogContainer === "" && (
        <Row className="send-documents-container-sec-row">
          <Col style={{ width: "100%" }}>
            <Row style={{ justifyContent: "center" }}>
              <label>
                <b>File Attached:-</b>
                {document.publicUrl && document.fileName
                  ? document.fileName
                  : "none "}
              </label>
            </Row>
            <Row style={{ justifyContent: "center" }}>
              <FileUpload
                id="image"
                name="image"
                accept="image/jpg, image/png, application/pdf"
                onLoad={(e, v) => _handleLoad(e, v)}
              />
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        {/* {sendDialogContainer === "whatsapp" && (
          <SendWhatsappComponent
            patient={patient}
            document={document}
            closeDialog={() => {
              setSendDialog(false);
              setsendDialogContainer("");
            }}
          />
        )} */}
        {sendDialogContainer === "sms" && (
          <SendSMSComponent
            patient={patient}
            document={document}
            closeDialog={() => {
              setSendDialog(false);
              props.closeSendDocument();
              setsendDialogContainer("");
            }}
            closeSms={() => {
              setsendDialogContainer("");
            }}
          />
        )}
        {sendDialogContainer === "email" && (
          <SendEmailComponent
            patient={patient}
            emailMessage={emailMessage}
            document={document}
            closeDialog={() => {
              setSendDialog(false);
              props.closeSendDocument();
              setsendDialogContainer("");
            }}
            closeEmail={() => {
              setsendDialogContainer("");
            }}
          />
        )}
      </Row>
    </Dialog>
  );
}

export default SendDocuments;
