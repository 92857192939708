import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class DoctorsService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  getAll(onResponse, onError) {
    let url = APIConfig.doctor.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  activateDoctor(doctorId, onResponse, onError) {
    let url = APIConfig.doctor.activate;
    let doctorRequest = {
      id: doctorId,
      fromStatus: 1,
      toStatus: 4,
    };

    return this.apiService.put(
      url,
      JSON.stringify(doctorRequest),
      onResponse,
      onError
    );
  }

  deActivateDoctor(doctorId, onResponse, onError) {
    let url = APIConfig.doctor.deactivate;
    let doctorRequest = {
      id: doctorId,
      fromStatus: 4,
      toStatus: 1,
    };

    return this.apiService.put(
      url,
      JSON.stringify(doctorRequest),
      onResponse,
      onError
    );
  }

  addNew(doctor, onResponse, onError) {
    let url = APIConfig.doctor.addNew;
    return this.apiService.postWithFormData(url, doctor, onResponse, onError);
  }

  update(doctorId, doctor, onResponse, onError) {
    let url = APIConfig.doctor.update + doctorId + "/update";
    return this.apiService.putWithFormData(url, doctor, onResponse, onError);
  }

  clientCode(onResponse, onError) {
    let url = APIConfig.whitelisting.getAll;
    return this.apiService.get(url, onResponse, onError);
  }
}

export default DoctorsService;
