import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class CallNoteService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(callNote, onResponse, onError) {
    let url = APIConfig.callNote.new;

    return this.apiService.post(url, callNote, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.callNote.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.callNote.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, callNote, onResponse, onError) {
    let url = APIConfig.callNote.update.format(id);

    return this.apiService.put(url, callNote, onResponse, onError);
  }

  disableCallNoteButton(id, callNote, onResponse, onError) {
    let url = APIConfig.callNote.disable.format(id);

    return this.apiService.put(url, callNote, onResponse, onError);
  }

  getAllByCall(callId, onResponse, onError) {
    let url = APIConfig.callNote.getAllByCall.format(callId);

    return this.apiService.get(url, onResponse, onError);
  }

  directMessageToPatient(Id, callNoteAndState, onResponse, onError) {
    let url = APIConfig.callNote.directMessage.format(Id);

    return this.apiService.post(url, callNoteAndState, onResponse, onError);
  }

  getUserIdOfPatient(patientId, onResponse, onError) {
    let url = APIConfig.callNote.getUserId.format(patientId);

    return this.apiService.get(url, onResponse, onError);
  }

  callNoteDetails(patientId, onResponse, onError) {
    let url = APIConfig.callNote.detailedNote.format(patientId);

    return this.apiService.get(url, onResponse, onError);
  }
  sendEmail(body, onResponse, onError) {
    let url = APIConfig.documentMail.sendEmail;
    return this.apiService.post(url, body, onResponse, onError);
  }
  sendSms(request, onResponse, onError) {
    let url = APIConfig.sms.sendSms;
    return this.apiService.post(url, request, onResponse, onError);
  }
  whatsappShort(body, onResponse, onError) {
    let url = APIConfig.whatsapp.short;
    return this.apiService.post(url, body, onResponse, onError);
  }
  audit(body, onResponse, onError) {
    let url = APIConfig.documentMail.audit;
    return this.apiService.post(url, body, onResponse, onError);
  }
}

export default CallNoteService;
