import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Moment from "react-moment";
import Divider from "react-md/lib/Dividers";
import Button from "react-md/lib/Buttons/Button";
import SelectField from "react-md/lib/SelectFields";

import Helper from "util/Helper";
import EventService from "service/event/EventService";
import AuthenticationService from "service/auth/AuthenticationService";

import PatientService from "modules/ops/modules/patients/service/PatientsService";
import CallService from "../modules/callplan/services/CallService";
import DoctorService from "modules/sales/modules/doctors/service/DoctorsService";

import CallPlan from "../modules/callplan/components/CallPlan";
import Checklist from "../modules/checklist/components/Checklist";
import TrackingDay from "./TrackingDay";
import Calls from "../modules/callplan/components/Calls";
import Medications from "../modules/medication/components/Medications";
import ChiefComplaints from "../modules/symptom/components/ChiefComplaints";

import "../styles/patient-tracking.css";
import UserService from "../../../../../../user/service/UserService";

class PatientTracking extends React.Component {
  constructor(props) {
    super(props);

    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();
    this.doctorService = new DoctorService();

    this.patientService = new PatientService();
    this.callService = new CallService();
    this.userService = new UserService();
    const { patientId } =
      props.match === undefined ? props : props.match.params;

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let yesterday = new Date(today.getTime());
    yesterday.setDate(today.getDate() - 1);

    let theDayBefore = new Date(today.getTime());
    theDayBefore.setDate(today.getDate() - 2);

    const dates = [
      theDayBefore.getTime(),
      yesterday.getTime(),
      today.getTime(),
    ];

    let doctorId = 13;
    if (
      this.authenticationService.getUserProfile().doctorProfile &&
      this.authenticationService.getUserProfile().doctorProfile.id > 0
    ) {
      doctorId = this.authenticationService.getUserProfile().doctorProfile.id;
    }

    this.state = {
      patientId: parseInt(patientId),
      doctorId: doctorId,
      doctorPhoneNumber: this.authenticationService.getUserProfile()
        .doctorProfile
        ? this.authenticationService.getUserProfile().doctorProfile.phoneno
        : "",
      patient: {
        params: [],
      },
      patientTrackingInfo: {
        checklists: [],
        careTeam: { doctors: [] },
      },
      calls: [],
      allDoctors: [],
      activeParamId: "",
      activeChecklistId: "",
      dates: dates,
      employee: this.authenticationService.getUserProfile() || {},
      readOnly: this.userService.isDoctor(),
    };

    this._initializeEventListeners();
  }

  componentDidMount() {
    this._fetchAllDoctors();
    this._fetchPatient();
    this._fetchPatientTrackingInfo();
  }

  render() {
    const { patient, patientTrackingInfo, calls } = this.state;
    let name = patient.firstName + " " + patient.lastName || "";
    name = name.substring(0, 8);

    const paramsList = patient.params.map(({ id, name, unit }) => (
      <div
        key={id}
        className={
          "item-param " + (id === this.state.activeParamId ? "highlight" : "")
        }
      >
        {name}
        &nbsp;
        <small>{unit.name}</small>
      </div>
    ));

    const checklists = patientTrackingInfo.checklists.map(({ id, master }) => (
      <div
        key={id}
        className={
          "item-checklist " +
          (id === this.state.activeChecklistId ? "highlight" : "")
        }
      >
        {master.name}
      </div>
    ));

    const trackColumns = this.state.dates.map((timestamp, index) => (
      <TrackingDay
        key={index}
        patientId={this.state.patientId}
        doctorId={this.state.doctorId}
        dueDate={timestamp}
        params={this.state.patient.params}
        checklists={this.state.patientTrackingInfo.checklists}
      />
    ));

    return (
      <div>
        <Row start="xs">
          <Col xs={12} sm={12} md={5} lg={5}>
            <div className="md-paper md-paper--1 sec-patient-tracking-info">
              <h2>
                {!this.props.fromNewBridge && name}
                {!this.props.fromNewBridge && !this.state.readOnly && (
                  <Button
                    icon
                    tooltipLabel="View Profile"
                    onClick={() =>
                      this.helper.navigateTo(
                        this.props,
                        "/patients/" + this.state.patientId
                      )
                    }
                  >
                    account_circle
                  </Button>
                )}
                {this.helper.isInAllowedRoles(
                  [
                    "BATMAN",
                    "Chief Executor",
                    "Care Manager",
                    "Care Team Doctor",
                    "All Read-Only",
                  ],
                  this.state.employee.roles
                ) && (
                  <span>
                    <small className="tiny"> </small>
                    <SelectField
                      id="doctor"
                      name="doctor"
                      placeholder="View as"
                      menuItems={
                        this.state.patientTrackingInfo.careTeam
                          ? this.state.patientTrackingInfo.careTeam.doctors
                          : []
                      }
                      itemLabel="name"
                      itemValue="phoneno"
                      className=""
                      required
                      value={this.state.doctorPhoneNumber}
                      onChange={this._onClickSelectCareTeamDoctor}
                    />
                  </span>
                )}
              </h2>
              {!this.props.fromNewBridge && (
                <div>
                  <h3 className="value-param">{patient.age}</h3>
                  Age
                  <hr />
                  <h4>Parameters</h4>
                  {paramsList}
                  <hr />
                  {patientTrackingInfo.checklists.length > 0 && false && (
                    <div>
                      <h4>Checklist</h4>
                      {checklists}
                      <hr />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Col>
          {!this.props.fromNewBridge && (
            <Col xs={12} sm={12} md={1} lg={1}>
              <Row end="xs">
                <Button
                  icon
                  secondary
                  swapTheming
                  onClick={() => this._slidePrevious()}
                >
                  arrow_left
                </Button>
              </Row>
            </Col>
          )}
          {!this.props.fromNewBridge && trackColumns}
          {!this.props.fromNewBridge && (
            <Col xs={12} sm={12} md={1} lg={1}>
              <Row start="xs">
                <Button
                  icon
                  secondary
                  swapTheming
                  onClick={() => this._slideNext()}
                >
                  arrow_right
                </Button>
              </Row>
            </Col>
          )}
        </Row>
        <br />
        <div>
          <Row start="xs">
            <Col xs={12} sm={12} md={12} lg={12}>
              <Calls
                navigo={this.props.navigo}
                planList={this.props.planList}
                patient={this.state.patient}
                readOnly={this.state.readOnly}
                patientId={this.state.patientId}
                doctorId={this.state.doctorId}
                patientPhoneNumber={this.state.patientPhoneNumber}
                doctorPhoneNumber={this.state.doctorPhoneNumber}
                allDoctors={this.state.allDoctors}
              />
            </Col>
          </Row>
          <br />
          {!this.props.fromNewBridge && (
            <Row start="xs">
              <Col xs={12} sm={12} md={6} lg={6}>
                <Medications
                  patientId={this.state.patientId}
                  doctorId={this.state.doctorId}
                  medications={this.state.patient.medications}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <ChiefComplaints
                  patientId={this.state.patientId}
                  chiefComplaints={this.state.patient.chiefComplaints}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }

  _initializeEventListeners = () => {
    EventService.on("onSelectParamField", (args) => {
      if (args.paramId) {
        this.setState({
          activeParamId: args.paramId,
        });
      }
    });

    EventService.on("onSelectChecklistField", (args) => {
      if (args.checklistId) {
        this.setState({
          activeChecklistId: args.checklistId,
        });
      }
    });
  };

  _slidePrevious = () => {
    const dates = this.state.dates;

    dates.forEach((date, index) => {
      let newDate = new Date(date);
      newDate.setDate(newDate.getDate() - 1);

      dates[index] = newDate.getTime();
    });

    this.setState({
      dates: dates,
    });
  };

  _slideNext = () => {
    const dates = this.state.dates;

    dates.forEach((date, index) => {
      let newDate = new Date(date);
      newDate.setDate(newDate.getDate() + 1);

      dates[index] = newDate.getTime();
    });

    this.setState({
      dates: dates,
    });
  };

  _onClickSelectCareTeamDoctor = (value, index, proxy) => {
    let doctorId = this.state.patientTrackingInfo.careTeam.doctors[index].id;

    this.setState(
      {
        doctorPhoneNumber: value,
        doctorId: doctorId,
      },
      () => {
        this._fetchPatientTrackingInfo();
      }
    );
  };

  _fetchPatient() {
    let onResponse = (data) => {
      this.setState({
        patient: data,
        patientPhoneNumber: data.phoneno,
      });
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });
    };

    this.patientService.getPatient(this.state.patientId, onResponse, onError);
  }

  _fetchPatientTrackingInfo = () => {
    let onResponse = (data) => {
      if (data.careTeam.doctors === null) {
        data.careTeam.doctors = [];
      }
      this.setState({
        patientTrackingInfo: data,
      });

      if (!this.state.doctorId) {
        this.setState({
          doctorId: data.careTeam.doctors[0].id,
        });
      }
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });
    };

    this.patientService.getTrackingInfo(
      this.state.patientId,
      this.state.doctorId,
      onResponse,
      onError
    );
  };

  _fetchAllDoctors = () => {
    let onResponse = (data) => {
      this.setState({
        allDoctors: data,
      });
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });
    };

    this.doctorService.getAll(onResponse, onError);
  };
}

export default PatientTracking;
