class Validator {
  constructor() {

  }

  validatePhoneNo(phoneNo) {
    let re = /^\d{10}$/;
    return re.test(phoneNo);
  }

  validateOTP(otp) {
    let re = /^\d{5}$/;
    return re.test(otp);
  }

  validateName(name) {
    let re = /^[a-zA-Z\\s]*$/;
    return re.test(name);
  }

  validateEmail(email) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validateEmailOrEmpty(email) {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$|^$/;
    return re.test(email);
  }

  validateAge(age) {
    let re = /^\d{1,3}$/;
    return re.test(age);
  }

  validateAddress(address) {
    let re = /^[a-zA-Z\-0-9]{1,}$/;
    return re.test(address);
  }

  validateGender(gender) {
    let re = /^[a-zA-Z]{1,}$/;
    return re.test(gender);
  }

  validateNumber(number) {
    let re = /^[0-9]{1,}$/;
    return re.test(number);
  }

  validateText(text) {
    let re = /.*\S.*/;
    return re.test(text);
  }
}

export default Validator;
