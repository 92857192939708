import React from "react";
import { withRouter, Link } from "react-router-dom";
import Button from "react-md/lib/Buttons";
import { Avatar } from "react-md";

import "./brand.css";

import Helper from "util/Helper";
import EventService from "service/event/EventService";
import AuthenticationService from "service/auth/AuthenticationService";

import logo from "assets/icons/logo_wide_black.png";
import { Col, Row } from "react-flexbox-grid";

class ClientBrand extends React.Component {
  constructor() {
    super();

    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();

    this.state = {
      updateAvailable: false,
      user: this.authenticationService.getUserProfile()
    };
  }

  componentDidMount() {
    EventService.on("updateAvailable", args => {
      this.setState({
        updateAvailable: true
      });
    });
  }

  render() {
    const { onClickLogout } = this.props;
    const { user } = this.state;
    const { doctorProfile } = user;
    return (
      <div className="container-brand">
        <div className="container-client-brand">
          <div className="profile-container">
            <div className="img-container">
              <Link to="/me">
                <Avatar
                  src={this._getProfileImage()}
                  role="presentation"
                  className="avatar-user"
                ></Avatar>
              </Link>
            </div>
            <Link to="/me">
              <div className="doctor-user">
                <span>{doctorProfile.name}</span>
                <span className="doctor-user-title">
                  {doctorProfile.clinicHospital} | {doctorProfile.location}
                </span>
              </div>
            </Link>
          </div>
          <div className="setting-container">
            <div className="client-name">
              <div className="brand-text clickable">Beyond Glucose Program</div>
            </div>
            <div className="logout-link">
              <strong onClick={() => onClickLogout()}>Logout</strong>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render1() {
    const { onClickLogout } = this.props;
    const { user } = this.state;
    const { doctorProfile } = user;
    return (
      <div className="container-brand container-client-brand ">
        <div className="clickable">
          <Row>
            <Avatar
              src={this._getProfileImage()}
              role="presentation"
              className="avatar-user"
            />
            <Col className="doctor-user">
              <Row>
                <span className="doctor-user-name">{doctorProfile.name}</span>
              </Row>
              <Row>
                <span className="doctor-user-title">
                  {doctorProfile.clinicHospital} | {doctorProfile.location}
                </span>
              </Row>
            </Col>
          </Row>
        </div>

        <Row className="no-mg">
          <Col className="p-15 no-mg">
            <Row className="no-mg">
              <strong className="brand-text clickable">
                Beyond Glucose Program
              </strong>
            </Row>
            <Row className="logout-link no-mg" onClick={() => onClickLogout()}>
              Logout
            </Row>
          </Col>
        </Row>
      </div>
    );
  }

  _refresh = () => {
    window.location.reload();
  };

  _getProfileImage = () => {
    return this.authenticationService.getUserProfileImage();
  };

  _openProfile = () => {
    this.helper.navigateTo(this.props, "/me");
  };
}

export default withRouter(ClientBrand);
