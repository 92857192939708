import * as React from "react";
import { Button, Menu } from "@material-ui/core";
import { Row, Col } from "react-flexbox-grid";
import { FontIcon } from "react-md";

import sortIcon from "assets/icons/height.png";
import "../styles/sort-component.css";
import EventService from "service/event/EventService";

export default function Sort(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  EventService.on("CLOSE_SORT", () => {
    setAnchorEl(null);
  });

  return (
    <div>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={sortIcon} />
        {/* Sort by */}
      </Button>
      <Menu
        id="sort-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Row className="main-component-sort">
          <Col className="full-width-compenent">
            <Row className="space-two-items full-width-compenent">
              <Col style={{ marginLeft: "10px" }}>
                <label className="sort-heading"> Sort by:</label>
              </Col>
              <Col
                onClick={handleClose}
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              >
                <FontIcon>close </FontIcon>
              </Col>
            </Row>
            <Row className="full-width-compenent">{props.documentField}</Row>
          </Col>
        </Row>
      </Menu>
    </div>
  );
}
