import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";
import Helper from "util/Helper";
class PatientProgressNotesService {
  constructor() {
    this.apiService = new APIService();
    this.helper = new Helper();
  }

  get(patientId, interval, number, onResponse, onError) {
    let url = APIConfig.newProgressNote.getAll.format(
      patientId,
      number,
      interval,
      new Date().getTime()
    );
    return this.apiService.get(url, onResponse, onError);
  }

  getWithType(patientId, interval, number, type, onResponse, onError) {
    let url = APIConfig.newProgressNote.getAllWithType.format(
      patientId,
      number,
      interval,
      type,
      new Date().getTime()
    );
    return this.apiService.get(url, onResponse, onError);
  }

  searchWithType(
    searchText,
    patientId,
    interval,
    number,
    type,
    onResponse,
    onError
  ) {
    let url = APIConfig.newProgressNote.search.format(
      patientId,
      number,
      interval,
      type,
      searchText
    );
    return this.apiService.get(url, onResponse, onError);
  }
  createProgressNote(progressNote, onResponse, onError) {
    let url = APIConfig.newProgressNote.new;
    return this.apiService.post(url, progressNote, onResponse, onError);
  }

  createComment(comment, onResponse, onError) {
    let url = APIConfig.progressNoteComment.new;
    return this.apiService.post(url, comment, onResponse, onError);
  }

  updateProgressNote(progressNotesId, progressNote, onResponse, onError) {
    let url = APIConfig.newProgressNote.update.format(progressNotesId);
    return this.apiService.patch(url, progressNote, onResponse, onError);
  }

  updateComment(commentId, comment, onResponse, onError) {
    let url = APIConfig.progressNoteComment.update.format(commentId);
    return this.apiService.patch(url, comment, onResponse, onError);
  }

  getEmployeeInfo(employeeId, onResponse, onError) {
    let url = APIConfig.user.get.format(employeeId);
    return this.apiService.get(url, onResponse, onError);
  }
  getDisposition(phoneno, onResponse, onError) {
    let url = "https://services.prod.zyla.in/api/v1/disposition/get/" + phoneno;

    return fetch(url, {
      method: "GET",
      headers: { Authorization: "Bearer 8bfe62522a70efb5e4ef0276fb872515" },
    })
      .then(function (response) {
        if (response.status === 204) {
          onResponse(undefined);
          return;
        }
        if (response.status !== 200) {
          response
            .json()
            .then(function (data) {
              onError(data);
            })
            .catch(function (error) {
              onError({
                message: "Looks like something went wrong. Please try again.",
              });
            });
          return;
        }
        // Examine the text in the response
        response.json().then(function (data) {
          onResponse(data);
          return;
        });
      })
      .catch(function (error) {
        if (error) {
          onError(error);
        } else {
          onError({
            message: "Looks like something went wrong. Please try again.",
          });
        }
        return;
      });
  }

  newTask(ele, onResponse, onError) {
    let url = APIConfig.progressNoteTaskZoho.newTask;
   
    return this.apiService.post(url, ele, onResponse, onError);
  }

  // getUsers(onResponse, onError) {
  //   let url = APIConfig.progressNoteTaskZoho.users;
  //   return this.apiService.get(url, onResponse, onError);
  // }
  //get users by project
  getUsersByProject(projectId, onResponse, onError) {
    let url = APIConfig.progressNoteTaskZoho.userByProject.format(projectId);
    return this.apiService.get(url, onResponse, onError);
  }
  getProjects(onResponse, onError) {
    let url = APIConfig.progressNoteTaskZoho.projects;
    return this.apiService.get(url, onResponse, onError);
  }
}

export default PatientProgressNotesService;
