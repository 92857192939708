import AuthenticationService from 'service/auth/AuthenticationService';
import EnvironmentService from 'service/environment/EnvironmentService';
import APIConfig from 'service/network/APIConfig';
import APIService from 'service/network/APIService';

class ChecklistService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(checklist, onResponse, onError) {
    let url = APIConfig.checklist.new;

    return this.apiService.post(url, checklist, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.checklist.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.checklist.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, checklist, onResponse, onError) {
    let url = APIConfig.checklist.update.format(id);

    return this.apiService.put(url, checklist, onResponse, onError);
  }

  getByPatientDoctor(patientId, doctorId, onResponse, onError) {
    let url = APIConfig.checklist.getByPatientDoctor.format(patientId, doctorId);

    return this.apiService.get(url, onResponse, onError);
  }
}

export default ChecklistService;
