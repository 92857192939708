import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class SendDocumentService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  create(request, headers, onResponse, onError) {
    let url = "https://services.prod.zyla.in/api/v1/zylawhatsapp/user";
    // APIConfig.whatsapp.create;
    return this.apiService.postWithOptionalHeaders(
      url,
      request,
      headers,
      onResponse,
      onError
    );
  }
  sendMessage(url, headers, onResponse, onError) {
    return this.apiService.getWithOptionalHeaders(
      url,
      headers,
      onResponse,
      onError
    );
  }
  event(request, headers, onResponse, onError) {
    // let url = APIConfig.whatsapp.create;
    let url = "https://services.prod.zyla.in/api/v1/zylawhatsapp/event";
    return this.apiService.postWithOptionalHeaders(
      url,
      request,
      headers,
      onResponse,
      onError
    );
  }
  whatsappEvent(body, onResponse, onError) {
    let url = APIConfig.whatsapp.event;
    return this.apiService.post(url, body, onResponse, onError);
  }
  whatsappShort(body, onResponse, onError) {
    let url = APIConfig.whatsapp.short;
    return this.apiService.post(url, body, onResponse, onError);
  }
  sendSms(request, onResponse, onError) {
    let url = APIConfig.sms.sendSms;
    return this.apiService.post(url, request, onResponse, onError);
  }
}

export default SendDocumentService;
