import React, { useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import DocumentService from "../../documents/services/DocumentService";
import SendDocumentService from "../services/SendDocumentService";
import { Button } from "react-md";
import { TextField, Radio } from "@material-ui/core";
import Validator from "util/Validator";
import { Success, Error } from "../components/EmailSuccess";
import "../style/index.css";

const whatsappEventList = [
  {
    name: "Doc consultation",
    value: "document_report",
    entityId: "1301158167847151139",
    templateId: "1107165910271061474",
    senderId: "ZYLAHE",
    body: "Dear {0}, your prescription from the doctor is here. Click {1} to download it. Thank you for choosing Zyla.",
  },
  {
    name: "Hra report",
    value: "ha_reminder",
    entityId: "1301158167847151139",
    templateId: "1107165910248917526",
    senderId: "ZYLAHE",
    body: "Dear {0}, your health assessment is here. Click {1} to download it. Thank you for trusting Zyla.",
  },
  {
    name: "Annual health checkup",
    value: "ahc_reminder",
    entityId: "1301158167847151139",
    templateId: "1107165910211599883",
    senderId: "ZYLAHE",
    body: "Dear {0}, your lab test report is here. Click {1} to download it. Thank you for trusting Zyla.",
  },
];
// const WHATSAPP_SERVICE_SECRET =
//   "MzQ4ZmJlMmUtOWU3MC00MzY4LTg5OTctNDkzYjc2YmE3NTFi";
function SendSMSComponent(props) {
  
  const documentService = new DocumentService();
  const sendDocumentService = new SendDocumentService();
  const validator = new Validator();

  const [screen, setScreen] = useState("one");
  // const [disableButton, setDisable] = useState(false);
  const [patient, setPatient] = useState(props.patient);

  const [phone, setPhone] = useState(props.patient.phoneno);
  const [emailError, setEmailError] = useState(false);
  const [name, setName] = useState(props.patient.firstName);
  const [nameError, setNameError] = useState(false);

  const [eventType, setEventType] = useState("");
  const [document, setDocument] = useState(props.document);
  // const [publicUrl, setPublicUrl] = useState(props.document.publicUrl);
  const [documentError, setDocumentError] = useState(false);
  const [shortUrl, setShortUrl] = useState(null);
  const creatEvent = () => {
    
    // setDisable(true);

    let requestBody = {
      phoneNo: phone.toString(),
      senderId: eventType.senderId,
      entityid: eventType.entityId,
      templateid: eventType.templateId,
      body: eventType.body.format(name, document.publicUrl),
    };

    let onResponse = () => {
      audit();
      setScreen("success");
    };
    let onError = (error) => {
      setScreen("error");
      // setDisable(false);
    };
    
    sendDocumentService.sendSms(requestBody, onResponse, onError);
  };

  const shortUrlFetch = () => {
    let requestBody = {
      originalURL: document.publicUrl,
    };

    let onResponse = (response) => {
      setShortUrl(response.secureShortURL);
      setScreen("three");
    };
    let onError = (error) => {};
    if (document.publicUrl.length > 0)
      documentService.whatsappShort(requestBody, onResponse, onError);
  };

  const audit = () => {
    let requestBody = {
      patientId: patient.id,
      email: patient.email,
      phone: phone,
      type: eventType.value,
      name: document.fileName,
      link: document.publicUrl,
      createdAt: new Date().toISOString(),
      status: "sent",
      channel: "sms",
    };
    let onResponse = () => {};
    let onError = (error) => {};

    documentService.audit(requestBody, onResponse, onError);
  };

  return (
    <div className="send-email-editor-container" style={{ width: "100%" }}>
      <div className="send-email-editor-top" style={{ display: "grid" }}>
        {screen === "one" && (
          <Row className="full-width">
            <Col className="full-width">
              <Row
                className="width-full-item-document-email"
                style={{ paddingLeft: "25px" }}
              >
                <label className="doucment-email-heading">
                  Patient Detials
                </label>
              </Row>
              <Row
                style={{ paddingLeft: "25px" }}
                className="width-full-item-document-email center-item-document-email"
              >
                <label className="doucment-email-label">
                  Name
                  {nameError && (
                    <label
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}
                    >
                      Name required!
                    </label>
                  )}
                </label>

                <TextField
                  value={name}
                  style={{
                    color: "black",
                    fontWeight: "200",
                  }}
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                    setNameError(false);
                    if (e.target.value.length === 0) {
                      setNameError(true);
                    }
                  }}
                ></TextField>
              </Row>

              <Row
                style={{ paddingLeft: "25px" }}
                className="width-full-item-document-email center-item-document-email"
              >
                <label className="doucment-email-label">
                  Phone
                  {emailError && (
                    <label
                      style={{
                        color: "red",
                        fontWeight: "200",
                        fontSize: "12px",
                      }}
                    >
                      Phone required!
                    </label>
                  )}
                </label>

                <TextField
                  value={phone}
                  style={{
                    color: "black",
                    fontWeight: "200",
                  }}
                  required
                  disabled={true}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setEmailError(false);
                    if (e.target.value.length === 0) {
                      setEmailError(true);
                    }
                  }}
                ></TextField>
              </Row>

              <Row
                className="width-full-item-document-email email-doc-btn"
                style={{
                  paddingLeft: "22px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  secondary
                  raised
                  onClick={() => {
                    props.closeSms();
                  }}
                >
                  Back
                </Button>
                <Button
                  raised
                  primary
                  onClick={() => {
                    if (name) {
                      if (phone) {
                        if (validator.validatePhoneNo(phone)) {
                          setScreen("two");
                          setEmailError(false);
                        } else {
                          setEmailError(true);
                        }
                      } else {
                        setEmailError(true);
                      }
                    } else {
                      setNameError(true);
                    }
                  }}
                >
                  Next
                </Button>
              </Row>
            </Col>
          </Row>
        )}
        {screen === "two" && (
          <div className="box-flex-send-doc">
            <label className="doucment-email-heading">SMS Template:</label>
          </div>
        )}
      </div>
      {screen === "two" && (
        <div>
          {whatsappEventList.map((event) => (
            <div
              key={event.value}
              onClick={() => {
                setEventType(event);
              }}
            >
              <Radio checked={event.value === eventType.value} />{" "}
              <label>
                <b> {event.name} </b>
              </label>{" "}
            </div>
          ))}
          {documentError && (
            <label style={{ color: "red" }}>Upload Document! </label>
          )}
        </div>
      )}

      {screen === "two" && (
        <div className="send-email-editor-bottom">
          <Button
            secondary
            raised
            style={{
              marginRight: "10px",
            }}
            onClick={() => {
              setScreen("one");
            }}
          >
            Back
          </Button>
          <Button
            primary
            raised
            disabled={!eventType}
            style={{
              marginRight: "10px",
            }}
            onClick={() => {
              if (
                document &&
                document.publicUrl !== undefined &&
                document.publicUrl &&
                document.publicUrl.length > 0
              ) {
                setDocumentError(false);
                if (eventType.value !== undefined) {
                  shortUrlFetch();
                }
              } else {
                setDocumentError(true);
              }
            }}
          >
            Next
          </Button>
        </div>
      )}

      {screen === "three" && (
        <Row xs={12} sm={12} md={12}>
          <Col xs={12} sm={12} md={12}>
            <span className="doucment-email-heading">SMS Body:</span>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <span className="doucment-email-label">
              {"Name: " + name}
              <br />
              {"Phone No: " + phone}
            </span>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <div>
              <p className="sms-body">
                {eventType.body && eventType.body.format(name, shortUrl)}
              </p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12}>
            <Row
              className="width-full-item-document-email email-doc-btn"
              style={{
                paddingLeft: "22px",
                display: "flex",
                justifyContent: "space-between",
              }}
              xs={12}
              sm={12}
              md={12}
            >
              <Button
                secondary
                raised
                onClick={() => {
                  setScreen("two");
                }}
              >
                Back
              </Button>
              <Button
                raised
                primary
                onClick={() => {
                  console.log("send button click")
                  if (name) {
                    if (phone) {
                      if (validator.validatePhoneNo(phone)) {
                       
                        if (document.publicUrl) {
                          
                          creatEvent();
                        }else{
                          console.log("public url is invalid", document.publicUrl)
                        }
                        setEmailError(false);
                      } else {
                        setEmailError(true);
                      }
                    } else {
                      setEmailError(true);
                    }
                  } else {
                    setNameError(true);
                  }
                }}
              >
                Send
              </Button>
            </Row>
          </Col>
        </Row>
      )}

      {screen === "success" && (
        <Success backButton={() => props.closeSms()} name={"SMS"} />
      )}
      {screen === "error" && (
        <Error backButton={() => setScreen("three")} name={"SMS"} />
      )}
    </div>
  );
}

export default SendSMSComponent;
