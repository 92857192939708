class StorageService {
  constructor() {
    this.prefix = 'zy.';
  }

  set(key, value) {
    try {
      localStorage.setItem(this.prefix + key, JSON.stringify(value));
    } catch (e) {
      // nothing
    } finally {
      // nothing
    }
  }

  get(key) {
    try {
      return JSON.parse(localStorage.getItem(this.prefix + key));
    } catch (e) {
      return undefined;
    }
  }

  clearAll() {
    localStorage.clear();
  }
}

export default StorageService;
