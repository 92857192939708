import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import FontIcon from 'react-md/lib/FontIcons';
import Card from 'react-md/lib/Cards/Card';
import { List, ListItem, ListItemControl } from 'react-md/lib/Lists';
import Checkbox from 'react-md/lib/SelectionControls/Checkbox';
import TextField from 'react-md/lib/TextFields';
import Button from 'react-md/lib/Buttons/Button';
import Divider from 'react-md/lib/Dividers';

import Helper from 'util/Helper';
import EventService from 'service/event/EventService';

import CallToDoService from '../services/CallToDoService';

import CallToDoItem from './CallToDoItem';

import '../styles/call-todo.css';

class CallToDo extends React.Component {
  constructor(props) {
    super(props);

    this.FILTER_ALL = 1;
    this.FILTER_ACTIVE = 2;
    this.FILTER_COMPLETED = 3;

    this.helper = new Helper();

    this.callToDoService = new CallToDoService();

    this.state = {
      callId: props.callId || '',
      callToDos: props.callToDos || [ ],
      sortedCallToDos: [ ],
      newCallToDo: {
        done: false,
        name: ''
      },
      filter: this.FILTER_ALL
    };
  }

  componentDidMount() {
    this._filter(this.state.filter);
  }

  componentWillReceiveProps(props) {
    if (props) {
      this.setState({
        callId: props.callId || '',
        callToDos: props.callToDos || [ ],
      }, () => {
        this._filter(this.state.filter);
      });
    }
  }

  render() {
    const callToDoItems = this.state.sortedCallToDos.map(({ id, done, name, call_id }) => (
      <div key={ id }>
        <CallToDoItem
          id={ id }
          done={ done }
          name={ name }
          call_id={ call_id }
          _updateCallToDo={ this._updateCallToDo }/>
        <Divider/>
      </div>
    ));

    return(
      <Col className="column-todo"
        xs={12}>
        <h2>
          Post Call ToDos
        </h2>
        <Row start="xs">
          <Col xs={3}>
            <Button flat label="all"
              primary={ this.state.filter === this.FILTER_ALL ? true : false }
              onClick={ () => this._filter(this.FILTER_ALL) }/>
          </Col>
          <Col xs={3}>
            <Button flat label="active"
              primary={ this.state.filter === this.FILTER_ACTIVE ? true : false }
              onClick={ () => this._filter(this.FILTER_ACTIVE) }/>
          </Col>
          <Col xs={3}>
            <Button flat label="completed"
              primary={ this.state.filter === this.FILTER_COMPLETED ? true : false }
              onClick={ () => this._filter(this.FILTER_COMPLETED) }/>
          </Col>
        </Row>
        <hr/>
        <div className="md-paper md-paper--1">
          <TextField
            id="newCallToDo"
            name="newCallToDo"
            placeholder="What needs to be done?"
            type="text"
            block
            paddedBlock
            value={ this.state.newCallToDo.name }
            onChange={ this._syncState }
            onKeyDown={ (event) => this._onKeyDown(event) }/>
          <Divider/>
          { callToDoItems }
        </div>
      </Col>
    );
  }

  _syncState = (value, proxy) => {
    let event = proxy.nativeEvent;
    let name = this.state.newCallToDo.name;

    switch (event.target.name) {
      case 'newCallToDo':
        name = value;
        this.setState({
          newCallToDo: {
            done: false,
            name: name
          }
        });
        break;
      default:
        break;
    }
  }

  _onKeyDown = (event) => {
    if (event.key === 'Enter') {
      this._addCallToDo();
    }
  }

  _addCallToDo = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      let callToDos = this.state.callToDos;
      callToDos.push(data);

      this.setState({
        callToDos: callToDos
      });

      this._resetNewCallToDo();
      this._filter(this.state.filter);

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    // pre-request parsing
    const { done, name } = this.state.newCallToDo;
    let callToDoRequest = { };
    callToDoRequest.call_id = this.state.callId;
    callToDoRequest.done = done;
    callToDoRequest.name = name;

    this.callToDoService.new(callToDoRequest, onResponse, onError);
  }

  _resetNewCallToDo = () => {
    this.setState({
      newCallToDo: {
        done: false,
        name: ''
      }
    });
  }

  _updateCallToDo = (callToDo) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      let callToDos = this.state.callToDos;
      let index = this.helper.findIndexByKey(callToDos, 'id', data.id);

      callToDos[index] = data;

      this.setState({
        callToDos: callToDos
      }, () => {
        this._filter(this.state.filter);
      });

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    // pre-request parsing
    let callToDoRequest = callToDo;

    this.callToDoService.update(callToDo.id, callToDoRequest, onResponse, onError);
  }

  _filter = (filterType) => {
    const callToDos = this.state.callToDos;
    let sortedCallToDos = this.state.sortedCallToDos;

    switch (filterType) {
      case this.FILTER_ALL:
        sortedCallToDos = callToDos;
        break;
      case this.FILTER_ACTIVE:
        sortedCallToDos = callToDos.filter((todo) => {
          if (!todo.done) {
            return true;
          }
        });
        break;
      case this.FILTER_COMPLETED:
        sortedCallToDos = callToDos.filter((todo) => {
          if (todo.done) {
            return true;
          }
        });
        break;
      default:
        break;
    }

    this.setState({
      filter: filterType,
      sortedCallToDos: sortedCallToDos
    });
  }
}

export default CallToDo;
