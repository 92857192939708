import AuthenticationService from 'service/auth/AuthenticationService';
import EnvironmentService from 'service/environment/EnvironmentService';
import APIConfig from 'service/network/APIConfig';
import APIService from 'service/network/APIService';

class CallToDoService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(callToDo, onResponse, onError) {
    let url = APIConfig.callToDo.new;

    return this.apiService.post(url, callToDo, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.callToDo.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.callToDo.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, callToDo, onResponse, onError) {
    let url = APIConfig.callToDo.update.format(id);

    return this.apiService.put(url, callToDo, onResponse, onError);
  }

  getAllByCall(callId, onResponse, onError) {
    let url = APIConfig.callToDo.getAllByCall.format(callId);

    return this.apiService.get(url, onResponse, onError);
  }
}

export default CallToDoService;
