import React, { useEffect, useRef, useState } from "react";

import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import backIcon from "../../../../../assets/icons/black-back-icon.svg";
import editPencilIcon from "../../../../../assets/icons/pencil-edit-icon.svg";
import editPencilIconOrange from "../../../../../assets/icons/orange-edit-icon.svg";
import appointIcon from "../../../../../assets/icons/navigo-appoint-icon.svg";
import EventService from "../../../../../service/event/EventService";
import NavigoAPIServices from "../services/navigoAPIServices";

import NavigoSingleService from "./NavigoSingleService";

function NavigoServices(props) {
  const [services, setServices] = useState([]);
  const [currentService, setCurrentService] = useState({});
  const [vendors, setVendors] = useState([]);
  const [packages, setPackages] = useState([]);
  const [layout, setLayout] = useState(1);

  const [isNew, setIsNew] = useState(false);

  const navigoAPIServices = new NavigoAPIServices();
  useEffect(() => {
    handleGetAllServices();
    fetchAllVendors();
    fetchAllPackages();
  }, []);

  const fetchAllVendors = () => {
    navigoAPIServices.getAllVendors(
      (res) => {
        let allVendors = [];
        if (res && res.length > 0) {
          allVendors = [...res];
        }
        setVendors(allVendors);
      },
      (err) => {
        EventService.emit("showError", {
          message: "Error while fetching vendors list.",
        });
      }
    );
  };

  const fetchAllPackages = () => {
    navigoAPIServices.getAllPackages(
      (res) => {
        let allPackages = [];
        if (res && res.length > 0) {
          allPackages = [...res];
        }
    
        setPackages(allPackages);
      },
      (err) => {
        EventService.emit("showError", {
          message: "Error while fetching vendors list.",
        });
      }
    );
  };

  const handleGetAllServices = () => {
    navigoAPIServices.getAllServices(
      (res) => {
        if (res && res.length > 0) {
          setServices([...res]);
        } else {
          EventService.emit("showError", {
            message: "No service found.",
          });
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "No service found.",
        });
      }
    );
  };

  const handleCancel = (isService = null) => {
    setIsNew(false);
    setLayout(1);
  };

  const handleOpenService = (id) => {
    let currService = services.find((service) => service.ID == id);
    setIsNew(false);
    setCurrentService(currService);
    setLayout(2);
  };

  const handleAddNewService = () => {
    setIsNew(true);
    setLayout(2);
  };

  const handleSuccessfullSave = (isService = {}) => {
    let newServices = [];
    if (isNew) {
      newServices = [...services, { ...isService }];
    } else {
      newServices = services.map((srv) => {
        if (srv.ID === isService.ID) {
          return { ...isService };
        }
        return { ...srv };
      });
    }
    setServices(newServices);
    setLayout(1);
  };

  return (
    <div className="navigo-services-layout-container">
      {layout === 1 && (
        <div className="navigo-services-layout-1-container">
          <div
            className="navigo-services-section-1"
            onClick={props.changeLayout}
            id="navigo_service_1"
          >
            <img src={backIcon} />
            <span>Back</span>
          </div>
          <div className="navigo-services-section-2">
            <h1 className="navigo-appoint-section-2-heading">Services</h1>
            <button onClick={handleAddNewService} id="navigo_service_2">
              + Add New
            </button>
          </div>
          <div className="navigo-services-section-3">
            {services.map((items) => (
              <ServicesItems
                {...items}
                openService={handleOpenService}
                key={items.ID}
              />
            ))}
          </div>
        </div>
      )}
      {layout === 2 && (
        <NavigoSingleService
          currentService={currentService}
          isEdit={true}
          isCustom={false}
          handleClose={() => {
            handleCancel();
          }}
          handleCloseSave={handleSuccessfullSave}
          isNew={isNew}
          vendors={vendors}
          packages={packages}
        />
      )}
    </div>
  );
}

function ServicesItems(props) {
  return (
    <div className="service-items-container">
      <img
        className="service-items-section-1"
        src={editPencilIcon}
        onClick={() => props.openService(props.ID)}
        id={`navigo_service_26_${props.ID}`}
      />
      <p
        className="service-items-section-2"
        id={`navigo_service_27_${props.ID}`}
        type="text"
      >
        {props.name}
      </p>
      <p
        className="service-items-section-3"
        id={`navigo_service_28_${props.ID}`}
        type="text"
      >
        {props.description}
      </p>
      {props.calendars && (
        <div className="service-items-section-4">
          <img src={appointIcon} id={`navigo_service_29_${props.ID}`} />
          <p id={`navigo_service_30_${props.ID}`} type="text">
            {props.calendars.length > 1
              ? `${props.calendars.length} calendars`
              : `${props.calendars.length} calendar`}{" "}
          </p>
        </div>
      )}
    </div>
  );
}

export default NavigoServices;
