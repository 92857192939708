import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class DocumentService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(documentModel, onResponse, onError) {
    let url = APIConfig.document.new;

    return this.apiService.postWithFormData(
      url,
      documentModel,
      onResponse,
      onError
    );
  }

  get(id, onResponse, onError) {
    let url = APIConfig.document.get.format(id);
    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.document.getAll;
    return this.apiService.get(url, onResponse, onError);
  }

  updateType(id, type, onResponse, onError) {
    let url = APIConfig.documentApis.updateType.format(id);
    return this.apiService.patch(url, { type: type }, onResponse, onError);
  }

  updateNameAndDescription(id, document, onResponse, onError) {
    let url = APIConfig.documentApis.updateNameAndDescription.format(id);
    return this.apiService.patch(url, document, onResponse, onError);
  }

  getAllFolders(onResponse, onError) {
    let url = APIConfig.documentApis.getAllFolders;
    return this.apiService.get(url, onResponse, onError);
  }
  addImage(imageForm, onResponse, onError) {
    const url = APIConfig.imageUpload.image;
    return this.apiService.postWithFormData(
      url,
      imageForm,
      onResponse,
      onError
    );
  }

  audit(body, onResponse, onError) {
    let url = APIConfig.documentMail.audit;
    return this.apiService.post(url, body, onResponse, onError);
  }
  sendEmail(body, onResponse, onError) {
    let url = APIConfig.documentMail.sendEmail;
    return this.apiService.post(url, body, onResponse, onError);
  }

  addImageNew(imageForm, onResponse, onError) {
    const url = APIConfig.documentMail.uploadDocument;
    return this.apiService.postWithFormData(
      url,
      imageForm,
      onResponse,
      onError
    );
  }


  whatsappEvent(body, onResponse, onError) {
    let url = APIConfig.whatsapp.event;
    return this.apiService.post(url, body, onResponse, onError);
  }
  whatsappShort(body, onResponse, onError) {
    let url = APIConfig.whatsapp.short;
    return this.apiService.post(url, body, onResponse, onError);
  }

  getAllDocuments(patientId, onResponse, onError) {
    let url = APIConfig.documentApis.getByPatientDoc.format(patientId);
    return this.apiService.get(url, onResponse, onError);
  }
  addNewDocument = (documentModel, onResponse, onError) => {
    let url = APIConfig.documentApis.uploadDoc;

    return this.apiService.postWithFormData(
      url,
      documentModel,
      onResponse,
      onError
    );
  };

}

export default DocumentService;
