import React from "react";
import { withRouter } from "react-router-dom";
import NavigationDrawer from "react-md/lib/NavigationDrawers";
import SelectField from "react-md/lib/SelectFields";
import Avatar from "react-md/lib/Avatars";

import Helper from "util/Helper";
import EventService from "service/event/EventService";
import AuthenticationService from "service/auth/AuthenticationService";

import GlobalLoader from "components/common/GlobalLoader";

import ClientBrand from "components/brand/ClientBrand";
import AccountMenu from "components/account-menu/AccountMenu";

import { doctorNavItems } from "constants/Navigation";

import "./drawer.css";

class DoctorDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.helper = new Helper();
    this.authenticationService = new AuthenticationService();

    this.state = {
      visible: false,
      position: "left",
      user: this.authenticationService.getUserProfile(),
    };
    this._navItems = doctorNavItems.map((item) => {
      if (!item.divider) {
        item.onClick = () => {
          this._setPage(item.key);
        };
      }
      return item;
    });
  }

  componentDidMount() {
    EventService.on("updateNavigation", (args) => {
      this._activatePage(args.key);
    });
  }

  render() {
    const user = this.state.user;
    let name = "User";
    if (user && user.employeeProfile) {
      name = user.employeeProfile.name;
    } else if (user && user.doctorProfile) {
      name = user.doctorProfile.name;
    }

    const drawerHeaderChildren = [
      <Avatar
        key={"1"}
        src={""}
        role="presentation"
        iconSized
        style={{
          alignSelf: "center",
          marginLeft: 16,
          marginRight: 16,
          flexShrink: 0,
        }}
      />,
      <SelectField
        id="account-switcher"
        menuItems={[name]}
        defaultValue={name}
        key="account-switcher"
        position={SelectField.Positions.BELOW}
        className="md-select-field--toolbar"
      />,
    ];

    const toolbarActions = (
      <div>
        <AccountMenu
          simplifiedMenu={false}
          image={null}
          name={"User"}
          onClickLogout={this._logout}
        />
      </div>
    );

    return (
      <NavigationDrawer
        navItems={this._navItems}
        contentClassName="md-grid"
        drawerHeaderChildren={drawerHeaderChildren}
        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY}
        tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
        toolbarTitle={<ClientBrand onClickLogout={this._logout} />}
        toolbarActions={null}
        toolbarChildren={<GlobalLoader />}
        toolbarThemeType={"themed"}
        toolbarClassName={"title-bar"}
        contentId="main-content"
      >
        {this.props.children}
      </NavigationDrawer>
    );
  }

  _setPage = (key) => {
    this._navItems = this._navItems.map((item) => {
      if (!item.divider) {
        item.active = item.key === key;
      }
      return item;
    });

    this.helper.navigateTo(this.props, key);
  };

  _activatePage = (key) => {
    this._navItems = this._navItems.map((item) => {
      if (!item.divider) {
        item.active = item.key === key;
      }
      return item;
    });
  };

  _logout = () => {
    console.log("logged out");
    this.authenticationService.logout();
    this.helper.navigateTo(this.props, "/doctor/login");
  };
}

export default withRouter(DoctorDrawer);
