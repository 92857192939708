import React from "react";
import "../style/index.css";
import sucessEmail from "assets/icons/success-email.png";
import errorEmail from "assets/icons/error-email.png";
import { Row, Col } from "react-flexbox-grid";
import { Button } from "@material-ui/core";

export function Success(props) {
  return (
    <Row xs={12} sm={12} md={12}>
      <Col xs={12} sm={6} md={6}>
        <img width="150px" alt="success-email" src={sucessEmail}></img>{" "}
      </Col>
      <Col
        xs={12}
        sm={6}
        md={6}
        style={{
          alignSelf: "center",
          fontSize: "24px",
        }}
      >
        <b> {props.name} sent </b>
      </Col>
      <Col
        xs={12}
        sm={12}
        md={12}
        style={{
          textAlign: "center",
        }}
      >
        <label
          style={{
            fontSize: "20px",
            fontWeight: "600",

            background: "#ef7c01",
            color: "white",
            fontWeight: "600",
            padding: "4px 10px 4px 10px",
            borderRadius: "17px",
          }}
          onClick={() => {
            props.backButton();
          }}
        >
          Back
        </label>
      </Col>
    </Row>
  );
}

export function Error(props) {
  return (
    <Row xs={12} sm={12} md={12}>
      <Col xs={12} sm={6} md={6}>
        <img width="150px" alt="error-email" src={errorEmail}></img>{" "}
      </Col>
      <Col
        xs={12}
        sm={6}
        md={6}
        style={{
          alignSelf: "center",
          fontSize: "24px",
        }}
      >
        <b> Error in sending {props.name}</b>
      </Col>
      <Col
        xs={12}
        sm={12}
        md={12}
        style={{
          textAlign: "center",
        }}
      >
        <label
          style={{
            fontSize: "20px",
            fontWeight: "600",
            background: "#ef7c01",
            color: "white",
            fontWeight: "600",
            padding: "4px 10px 4px 10px",
            borderRadius: "17px",
          }}
          onClick={() => {
            props.backButton();
          }}
        >
          Back
        </label>
      </Col>
    </Row>
  );
}

function LogScreen(props) {
  return (
    <div className="send-email-editor-container">Error in sending email</div>
  );
}
