import React from 'react';
import CSSTransitionGroup from 'react-addons-css-transition-group';
import LinearProgress from 'react-md/lib/Progress/LinearProgress';

import EventService from 'service/event/EventService';

const progressId = 'contentLoadingProgress';

class GlobalLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // awh
      refreshing: false,
      key: Math.random(),
    };
  }

  componentDidMount() {
    let that = this;
    EventService.on('startLoader', (params) => {
      that.startLoading();
    });

    EventService.on('stopLoader', (params) => {
      that.stopLoading();
    });
  }

  render() {
    const { refreshing, key } = this.state;

    let accessibilityProps;
    if (refreshing) {
      accessibilityProps = {
        'aria-busy': true,
        'aria-describedby': progressId,
      };
    }

    return(
      <CSSTransitionGroup
        component="div"
        className="loader-global"
        transitionName="opacity"
        transitionEnterTimeout={150}
        transitionLeaveTimeout={150}
        {...accessibilityProps}>
        {refreshing && <LinearProgress key="progress" id={progressId} />}
      </CSSTransitionGroup>
    );
  }

  startLoading() {
    this.setState({
      refreshing: true,
    });
  }

  stopLoading() {
    this.setState({
      refreshing: false,
    });
  }
}

export default GlobalLoader;
