import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Button from "react-md/lib/Buttons/Button";
import Moment from "react-moment";
import { Radio } from "@material-ui/core";
import FontIcon from "react-md/lib/FontIcons";

import Helper from "util/Helper";
import EventService from "service/event/EventService";
import ChiefComplaintService from "../services/ChiefComplaintService";
import Month from "../../../../new-bridge/components/DateCalculator/Months";
import Sort from "../../../../new-bridge/components/SortComponenet";

import "../styles/chief-complaints.css";
import "../../../../new-bridge/styles/sort-component.css";

class ChiefComplaints extends React.Component {
  constructor(props) {
    super(props);
    // returnStatus
    this.helper = new Helper();
    this.chiefComplaintService = new ChiefComplaintService();

    this.state = {
      sortList: [],
      sortField: "",
      chiefComplaintCount: 0,
      chiefComplaintData: [],
      desiredOrder : ["REFLD3", "REFLD5", "REFLD1", "REFLD2", "REFLD4"],
      chiefComplaintstoString: "",
      patientId: props.patientId || "",
      chiefComplaints: props.chiefComplaints || [],
      chiefComplaint: {
        patientId: props.patientId || "",
        content: props.content || "",
      },
      error: {
        content: "",
      },
      pageNo: 1,
      highlight: "",
    };
  }
  componentDidMount() {
    this.getCount();
  }

  

  customComparator = (a,b) => {

    const indexA = this.state.desiredOrder?.indexOf(a?.status)
    const indexB = this.state.desiredOrder?.indexOf(b?.status)

    return indexA - indexB;
  
  }

  componentWillReceiveProps(props) {
    if (props) {
      if (props.updateDataSet) {
        this.setState({ pageNo: 1 });
      }
  
      const sortedChiefComplaint = props?.chiefComplaint?.map((ele) => {
        const innerArray  = ele?.chiefComplaints.sort(this.customComparator)
        ele.chiefComplaints = innerArray
        return ele;
        
      })

      console.log("sorted",sortedChiefComplaint)
      

      this.setState({
        dataTables: props.dataTables,
        patientId: props.patientId || "",
        chiefComplaints: props.chiefComplaints || [],
        chiefComplaintData: sortedChiefComplaint,
      });
      
    }
    
  }

  getCount = () => {
    let onResponse = (data) => {
      this.setState({ chiefComplaintCount: data });
    };
    let onError = (err) => {
      EventService.emit("showError", { message: err.message });
    };
    this.chiefComplaintService.getChiefComplaintsCount(
      this.state.patientId,
      onResponse,
      onError
    );
  };
  findIntensity(value) {
    if (
      value !== "" &&
      value &&
      this.state.dataTables.CHIEF_COMPLAINTS_INTENSITY
    ) {
      let result = this.state.dataTables.CHIEF_COMPLAINTS_INTENSITY.find(
        (e) => e.value === value
      );
      if (result !== undefined) {
        return result.label + " | ";
      } else return "";
    } else return "";
  }

  findFrequency(value) {
    if (
      value !== "" &&
      value &&
      this.state.dataTables.CHIEF_COMPLAINTS_FREQUENCY
    ) {
      let result = this.state.dataTables.CHIEF_COMPLAINTS_FREQUENCY.find(
        (e) => e.value === value
      );
      if (result !== undefined) {
        return result.label;
      } else {
        return "";
      }
    } else return "";
  }

  findStatus(value) {
    if (
      value !== "" &&
      value &&
      this.state.dataTables.CHIEF_COMPLAINTS_STATUS
    ) {
      let result = this.state.dataTables.CHIEF_COMPLAINTS_STATUS.find(
        (e) => e.value === value
      );
      if (result !== undefined) return " | " + result.label;
      else return "";
    } else return "";
  }
  findMonth(value) {
    if (value) {
      var a = value.toString();
      if (a.length < 2) {
        a = "0" + value;
      }
      return Month.find((e) => e.value === value)?.label;
    } else return "";
  }

   JSONtoString(chiefComplaint) {
    var chiefComplaintstoString;
    if (chiefComplaint) {
      chiefComplaintstoString = chiefComplaint.map((e, index) => (
        <div key={"chiefComplaint" + index}>
          <label className="timeStamp">
            {" "}
            Posted by {e.createdByName} at
            <Moment format=" hh:mm a">{e.dateCreated}</Moment> on{" "}
            <Moment format="MMM DD YYYY ">{e.dateCreated}</Moment>
          </label>

          <div className="item-chief-complaint">
            {e.chiefComplaints.map((item, subIndex) => (
              <div key={subIndex + "subIndexChief"} className="subindex">
                {subIndex + 1 + ". "}
                {item.ongoing !== undefined && !item.ongoing && (
                  <label style={{ color: "red", fontWeight: "bold" }}>
                    History of{" "}
                  </label>
                )}
                <b>{item.complaintCode ? item.complaintCode.label : ""}</b>
                {item.yearDuration === 0 && item.monthDuration === 0
                  ? " Since " +
                    (item.dayDuration
                      ? item.dayDuration > 1
                        ? item.dayDuration + " Days"
                        : item.dayDuration + " Day"
                      : "")
                  : " Since " +
                    (item.yearDuration
                      ? item.yearDuration > 1
                        ? item.yearDuration + " Years "
                        : item.yearDuration + " Year "
                      : "") +
                    (item.monthDuration
                      ? item.monthDuration > 1
                        ? item.monthDuration + " Months"
                        : item.monthDuration + " Month"
                      : "")}
                {" ("}
                {item.daySinceWhen && item.monthSinceWhen && item.yearSinceWhen
                  ? item.daySinceWhen +
                    " " +
                    this.findMonth(item.monthSinceWhen) +
                    " " +
                    item.yearSinceWhen +
                    " - "
                  : ""}
                {item.ongoing ? "Present" : ""}
                {item.ongoing
                  ? ""
                  : item.dayTo && item.monthTo && item.yearTo
                  ? item.dayTo +
                    " " +
                    this.findMonth(item.monthTo) +
                    " " +
                    item.yearTo
                  : ""}
                {")"}
                {" " + this.findIntensity(item.intensity)}
                {!item.intensity
                  ? item.frequencyValue && item.frequency
                    ? " | " +
                      item.frequencyValue +
                      " " +
                      this.findFrequency(item.frequency) +
                      " | "
                    : " | " +
                      item.frequencyValue +
                      " " +
                      this.findFrequency(item.frequency) +
                      " | "
                  : item.frequencyValue +
                    " " +
                    this.findFrequency(item.frequency) +
                    " | "}
                {item.remainsOrRelieves ? item.remainsOrRelieves + " | " : ""}
                {"  " +
                  "Consulted a Doctor - " +
                  (item.doctorConsulted ? "YES" : "NO") +
                  " " +
                  "|" +
                  " Taking Medicine - " +
                  (item.takesMedicine ? "YES" : "NO")}
                {item.description ? " | " + item.description : "  "}
                <b>{this.findStatus(item.status)}</b>
              </div>
            ))}
          </div>
        </div>
      ));
      return chiefComplaintstoString;
    } else {
      chiefComplaintstoString = <div>...</div>;
      return chiefComplaintstoString;
    }
  }

  getChiefcomplaint = (patientId, pagesize, pageNo) => {
    let onResponse = (data) => {
      var chief = this.state.chiefComplaintData;
      if (data !== null) data.map((e) => chief.push(e));
      this.setState({ chief });
    };
    let onError = (err) => {
      EventService.emit("showError", { message: err.message });
    };
    var pageNo = this.state.pageNo;
    pageNo++;
    this.setState({ pageNo });

    this.chiefComplaintService.getNext(
      patientId,
      pagesize,
      pageNo,
      onResponse,
      onError
    );
  };

  getSortComponent = () => {
    const { dataTables, sortField, sortList } = this.state;

    return (
      <Row className="full-width-medications">
        <Col className="full-width-medications">
          <Row className="full-width-medications left-space-heading">
            <Radio
              onClick={() => {
                this.setState({
                  sortField: "ongoing",
                  sortList: [
                    { label: "Yes", value: "true" },
                    { label: "No", value: "false" },
                    { label: "None", value: "null" },
                  ],
                });
              }}
              checked={sortField === "ongoing"}
            ></Radio>{" "}
            <label className="sort-list-label" style={{ marginTop: "11px" }}>
              Ongoing
            </label>
          </Row>
          {sortField === "ongoing" && (
            <Row
              className="full-width-medications"
              style={{ marginLeft: "41px" }}
            >
              <Col className="left-space-heading">{this.getList()}</Col>
            </Row>
          )}
          <Row className="left-space-heading">
            <Radio
              onClick={() => {
                this.setState({
                  sortField: "intensity",
                  sortList: dataTables.CHIEF_COMPLAINTS_INTENSITY,
                });
              }}
              checked={sortField === "intensity"}
            ></Radio>{" "}
            <label className="sort-list-label" style={{ marginTop: "11px" }}>
              Intensity
            </label>
          </Row>
          {sortField === "intensity" && (
            <Row
              className="full-width-medications"
              style={{ marginLeft: "18px" }}
            >
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="left-space-heading"
              >
                {this.getList()}
              </Col>
            </Row>
          )}
          <Row className="left-space-heading">
            <Radio
              onClick={() => {
                this.setState({
                  sortField: "status",
                  sortList: dataTables.CHIEF_COMPLAINTS_STATUS,
                });
              }}
              checked={sortField === "status"}
            ></Radio>{" "}
            <label className="sort-list-label" style={{ marginTop: "11px" }}>
              Status
            </label>
          </Row>
          {sortField === "status" && (
            <Row
              className="full-width-medications"
              style={{ marginLeft: "18px" }}
            >
              <Col
                className="left-space-heading"
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                {this.getList()}
              </Col>
            </Row>
          )}
          <Row className="grey-sort-border"></Row>
          <Row
            style={{
              float: "right",
              marginRight: "4px",
            }}
          >
            <Button
              // raised
              primary
              onClick={() => {
                const { sortList, sortField, chiefComplaintData } = this.state;
                this.sortList(sortList, sortField, chiefComplaintData);
              }}
            >
              Apply
            </Button>
          </Row>
        </Col>
      </Row>
    );
  };

  getList = () => {
    let list = this.state.sortList;
    const { highlight } = this.state;
    if (list && list !== undefined) {
      return list.map((item, index) => (
        <Row
          xs={12}
          sm={12}
          md={12}
          lg={12}
          key={index}
          id={index}
          draggable
          onDragStart={(e) => {
            this.setState({ dragId: e.target.id });
          }}
          onDrop={(e) => {
            const { sortField } = this.state;
            var pickLoc = this.state.dragId;

            var dropLoc = e.target.id;

            if (pickLoc === dropLoc) {
              EventService.emit("showError", {
                message: "Same index cannot be updated",
              });
            } else {
              var object = list[parseInt(pickLoc)];
              // this.changeItemOrder(obj, dropLoc, pickLoc);
              console.log(object, dropLoc, pickLoc);
              dropLoc = parseInt(dropLoc);
              pickLoc = parseInt(pickLoc);
              var length = list.length;
              console.log(object, dropLoc, pickLoc);
              if (parseInt(dropLoc) === 0) {
                //first set

                list.splice(pickLoc, 1);
                // console.log("first", list);
                // list.splice(dropLoc, 0, object);
                let newList = [object];
                list.map((e) => newList.push(e));
                list.push(object);
                this.setState({ sortList: newList });
                // this.arraymove(list, pickLoc, dropLoc);
              } else if (parseInt(dropLoc) === length - 1) {
                // list.splice(pickLoc, 1);
                // list.splice(dropLoc, 0, object);
                list.splice(pickLoc, 1);
                let newList = [];
                list.map((e) => newList.push(e));
                newList.push(object);
                this.setState({ sortList: newList });
                // this.arraymove(list, pickLoc, dropLoc);
              } else {
                // this.arraymove(list, pickLoc, dropLoc);
                list.splice(pickLoc, 1);
                list.splice(dropLoc, 0, object);
                this.setState({ sortList: list }, () => {});
              }
              this.setState({ highlight: object.value }, () => {
                setInterval(() => {
                  this.setState({ highlight: "" });
                }, 4000);
              });
              //   this.setState({ sortList: list }, () => {});
            }
          }}
          onDragOver={(e, i, j) => {
            e.preventDefault();
          }}
        >
          <label id={index} className="dragIcon">
            =
          </label>
          <label
            id={index}
            className={
              highlight === item.value
                ? "highlight-item-sort sort-items"
                : "sort-items"
            }
          >
            {item.label}
          </label>{" "}
        </Row>
      ));
    } else return "";
  };

  arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    this.setState({ sortList: arr });
  }

  sortList = (sortList, fieldToSort, listToSort) => {
    let mainList = [];

    listToSort.map((medicine, index) => {
      let eachMeds = {};
      eachMeds = medicine;

      let allMedicine = [];

      let medsTest = {};
      sortList.map((sort) => {
        if (sort.value === true) {
          medsTest["true"] = [];
        } else if (sort.value === false) {
          medsTest["false"] = [];
        } else medsTest[sort.value] = [];
      });
      medsTest["null"] = [];

      medicine.chiefComplaints.map((meds) => {
        if (meds[fieldToSort] === true) {
          medsTest["true"].push(meds);
        } else if (meds[fieldToSort] === false) {
          medsTest["false"].push(meds);
        } else if (
          meds[fieldToSort] === "" ||
          meds[fieldToSort] === null ||
          meds[fieldToSort] === undefined
        ) {
          medsTest["null"].push(meds);
        } else {
          medsTest[meds[fieldToSort]].push(meds);
        }
      });
      //added to list

      sortList.map((sort) => {
        if (medsTest[sort.value]) {
          medsTest[sort.value].map((e) => {
            allMedicine.push(e);
          });
        }
        // else if(medsTest[sort.value]===){

        // }
      });

      if (medsTest["null"]) {
        medsTest["null"].map((e) => {
          allMedicine.push(e);
        });
      }

      eachMeds.chiefComplaints = allMedicine;

      mainList.push(eachMeds);
    });

    EventService.emit("showError", { message: "Tracking data updated" });
    EventService.emit("CLOSE_SORT");
    this.setState({ chiefComplaintData: mainList });
  };

  render() {
    const { chiefComplaintCount, chiefComplaintData } = this.state;

    return (
      <div className="md-paper md-paper--1" style={{ overflow: "hidden" }}>
        <div className="container-add-button-and-heading container-chief-complaints-buttons">
          <h2>{"Chief Complaints "}</h2>

          <div style={{ display: "flex" }}>
            <Sort documentField={this.getSortComponent()} />

            <Button
              raised
              primary
              onClick={() => {
                EventService.emit("tracking_update", true, 0);
              }}
            >
              UPDATE
            </Button>
          </div>
        </div>

        <div className="container-chief-complaints-data">
          <div className="container-chief-complaints-data-str">
            {this.JSONtoString(chiefComplaintData)}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <label className="timeStamp">
              Showing{" "}
              {chiefComplaintData
                ? "(1 - " + chiefComplaintData.length + ") "
                : 0}
              of {" " + chiefComplaintCount + " "}
            </label>
            <Button
              primary
              onClick={() =>
                this.getChiefcomplaint(
                  this.state.patientId,
                  3,
                  this.state.pageNo
                )
              }
            >
              SHOW MORE
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ChiefComplaints;
