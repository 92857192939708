import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import FontIcon from 'react-md/lib/FontIcons';
import Button from 'react-md/lib/Buttons/Button';
import Divider from 'react-md/lib/Dividers';
import Autocomplete from 'react-md/lib/Autocompletes';

import Helper from 'util/Helper';
import EventService from 'service/event/EventService';

import ChecklistService from '../services/ChecklistService';
import ChecklistMasterService from 'modules/misc/modules/checklists/services/ChecklistMasterService';

import '../styles/checklist.css';

class Checklist extends React.Component {
  constructor(props) {
    super(props);

    this.helper = new Helper();

    this.checklistService = new ChecklistService();
    this.checklistMasterService = new ChecklistMasterService();

    this.state = {
      patientId: props.patientId || '',
      doctorId: props.doctorId || '',
      checklists: props.checklists || [ ]
    }
  }

  componentDidMount() {
    this._fetchAllChecklistMasters();
    this._fetchChecklists();
  }

  componentWillReceiveProps(props) {
    if (props) {
      this.setState({
        patientId: props.patientId || '',
        doctorId: props.doctorId || '',
      }, () => {
        this._fetchChecklists();
      });
    }
  }

  render() {
    const checklists = this.state.checklists.map((checklist, index) => (
      <div key={ checklist.id }>
        <div className="item-checklist">
          <h4>
            { checklist.master.name }
          </h4>
          <p>
            { checklist.master.description }
          </p>
        </div>
        { (index + 1) !== this.state.checklists.length &&
          <Divider/>
        }
      </div>
    ));

    return(
      <div>
        <Row start="xs">
          <Col
            xs={12}>
            <div className="md-paper md-paper--1 container-checklist">
              <h2>
                Checklist
              </h2>
              <Autocomplete
                id="checklists"
                placeholder="Add an item"
                block
                data={ this.state.allChecklistMasters }
                dataLabel="name"
                onAutocomplete={ this._onAddChecklist }
                clearOnAutocomplete/>
              { checklists }
              <hr/>
              <Button
                raised
                primary
                label="Checklist Master List"
                onClick={ () => this.helper.navigateTo(this.props.routeProps, '/miscellaneous/checklists/master') }>
                format_list_numbered
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  _onAddChecklist = (checklistMaster, checklistMasterIndex, allChecklistMasters) => {
    this._addChecklist(allChecklistMasters[checklistMasterIndex].id);
  }

  _fetchChecklists = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this.setState({
        checklists: data,
      });

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    if (this.state.patientId && this.state.doctorId) {
      this.checklistService.getByPatientDoctor(this.state.patientId, this.state.doctorId, onResponse, onError);
    } else {
      EventService.emit('showError', { message: 'Not a valid Doctor-Patient combination' });
    }
  }

  _addChecklist = (masterId) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this._fetchChecklists();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    // pre-request parsing
    let checklistRequest = { };
    checklistRequest.patient_id = this.state.patientId;
    checklistRequest.doctor_id = this.state.doctorId;
    checklistRequest.master_id = masterId;

    if (this.state.patientId && this.state.doctorId) {
      this.checklistService.new(checklistRequest, onResponse, onError);
    } else {
      EventService.emit('showError', { message: 'Not a valid Doctor-Patient combination' });
    }
  }

  _fetchAllChecklistMasters = () => {
    let onResponse = (data) => {
      this.setState({
        allChecklistMasters: data,
      });
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });
    }

    this.checklistMasterService.getAll(onResponse, onError);
  }
}

export default Checklist;
