import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Button from 'react-md/lib/Buttons/Button';
import Divider from 'react-md/lib/Dividers';

import Helper from 'util/Helper';
import EventService from 'service/event/EventService';

import CallService from '../services/CallService';

import Call from './Call';

import '../styles/call-plan.css';

class CallPlan extends React.Component {
  constructor(props) {
    super(props);

    this.FILTER_ALL = 1;
    this.FILTER_TODAY = 2;

    this.helper = new Helper();

    this.callService = new CallService();

    this.state = {
      patientId: props.patientId || '',
      doctorId: props.doctorId || '',
      patientPhoneNumber: props.patientPhoneNumber || '',
      doctorPhoneNumber: props.doctorPhoneNumber || '',
      calls: [ ],
      sortedCalls: [ ],
      createMode: false,
      call: {
        caller: props.doctorPhoneNumber || '',
        callee: props.patientPhoneNumber || '',
        plan: '',
        isCaregiver: false,
        dueDate: new Date().getTime(),
        startTime: new Date().getTime(),
        due_date: new Date().getTime(),
        start_time: new Date().getTime(),
      },
      filter: this.FILTER_TODAY
    };

    EventService.on('onAddCall', (args) => {
      this.setState({
        createMode: args.createMode
      });
    })
  }

  componentDidMount() {
    this._fetchCalls();
  }

  componentWillReceiveProps(props) {
    if (props) {
      let call = this.state.call;
      call.caller = props.doctorPhoneNumber;
      call.callee = props.patientPhoneNumber;
      call.plan = '';
      call.isCaregiver = false;
      call.dueDate = new Date().getTime();
      call.startTime = new Date().getTime();
      call.due_date = new Date().getTime();
      call.start_time = new Date().getTime();

      this.setState({
        patientId: props.patientId || '',
        doctorId: props.doctorId || '',
        patientPhoneNumber: props.patientPhoneNumber || '',
        doctorPhoneNumber: props.doctorPhoneNumber || '',
        call: call
      }, () => {
        this._fetchCalls();
      });
    }
  }

  render() {
    const calls = this.state.sortedCalls.map((call, index) => (
      <div key={ call.id }>
        <Call
          call={ call }
          _fetchCalls={ this._fetchCalls }/>
        { (index + 1) !== this.state.calls.length &&
          <Divider/>
        }
      </div>
    ));

    return(
      <div>
        <Row start="xs">
          <Col
            xs={12}>
            <div className="md-paper md-paper--1 container-call-plan">
              <h2>
                Call Plan
              </h2>
              <Row start="xs">
                <Col xs={3}>
                  <Button flat label="today"
                    primary={ this.state.filter === this.FILTER_TODAY ? true : false }
                    onClick={ () => this._filter(this.FILTER_TODAY) }/>
                </Col>
                <Col xs={3}>
                  <Button flat label="all"
                    primary={ this.state.filter === this.FILTER_ALL ? true : false }
                    onClick={ () => this._filter(this.FILTER_ALL) }/>
                </Col>
              </Row>
              { this.state.calls.length > 0 &&
                <hr/>
              }
              { calls }
              <hr/>
              { !this.state.createMode &&
                <div>
                  <Button
                    raised
                    primary
                    label="Add a call"
                    onClick={ () => this._onClickAddCall() }>
                    add
                  </Button>
                </div>
              }
              { this.state.createMode &&
                <Call
                  call={ this.state.call }
                  createMode={ this.state.createMode }
                  _onClickAddCallPlan={ this._onClickAddCallPlan }
                  _fetchCalls={ this._fetchCalls }/>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  _onClickAddCall = () => {
    this.setState({
      createMode: true
    });
  }

  _onClickAddCallPlan = (dueDate, callback) => {
    this._addCallPlan(dueDate, callback);
  }

  _filter = (filterType) => {
    const calls = this.state.calls;
    let sortedCalls = this.state.sortedCalls;

    switch (filterType) {
      case this.FILTER_ALL:
        sortedCalls = calls;
        break;
      case this.FILTER_TODAY:
        sortedCalls = calls.filter((call) => {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let tomorrow = new Date(today.getTime());
          tomorrow.setDate(today.getDate() + 1);

          if (call.due_date >= today.getTime() && call.due_date < tomorrow.getTime()) {
            return true;
          }
        });
        break;
      default:
        break;
    }

    this.setState({
      filter: filterType,
      sortedCalls: sortedCalls
    });
  }

  _fetchCalls = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this.setState({
        calls: data
      });

      this._filter(this.FILTER_TODAY);

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      //EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    if (this.state.patientPhoneNumber && this.state.doctorPhoneNumber) {
      this.callService.getCallPlan(this.state.doctorPhoneNumber, this.state.patientPhoneNumber, 0, onResponse, onError);
    } else {
      EventService.emit('showError', { message: 'Not a valid Doctor-Patient combination' });
    }
  }
}

export default CallPlan;
