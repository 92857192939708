import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class CallService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(call, onResponse, onError) {
    let url = APIConfig.call.new;

    return this.apiService.post(url, call, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.call.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.call.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, call, onResponse, onError) {
    let url = APIConfig.call.update.format(id);

    return this.apiService.put(url, call, onResponse, onError);
  }

  getCallPlan(caller, callee, dueDate, onResponse, onError) {
    let url = APIConfig.call.getCallPlan.format(caller, callee, dueDate);

    return this.apiService.get(url, onResponse, onError);
  }

  getCallPlanByCallee(callee, fromDate, toDate, onResponse, onError) {
    let url = APIConfig.call.getCallPlanByCallee.format(
      callee,
      fromDate,
      toDate
    );

    return this.apiService.get(url, onResponse, onError);
  }

  createDisposition(
    phonNo,
    status,
    disposition,
    patient,
    haFlag,
    onResponse,
    onError
  ) {
    let finalDisposition = "";
    if (status === "successfull") {
      finalDisposition = "Successful/";
    }
    let url =
      APIConfig.tentacle.disposition +
      "?phone=" +
      phonNo +
      "&name=" +
      patient.firstName +
      "&disposition=" +
      finalDisposition +
      disposition.label +
      "&startsAt=" +
      (haFlag ? patient.startsAt : "") +
      "&endsAt=" +
      (haFlag ? patient.endsAt : "") +
      "&email=" +
      patient.email +
      "&preferredLanguage=" +
      patient.preferredLanguage +
      "&source=Bridge";

    return this.apiService.get(url, onResponse, onError);
  }
  fetchDetails(phone, onResponse, onError) {
    let url = APIConfig.tentacle.fetchDetails.format(phone);
    return this.apiService.get(url, onResponse, onError);
  }
  whatsappEvent(body, onResponse, onError) {
    let url = APIConfig.whatsapp.event;
    return this.apiService.post(url, body, onResponse, onError);
  }
}

export default CallService;
