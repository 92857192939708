import React from 'react';
import { Prompt } from "react-router-dom";
import { Grid, Row, Col } from 'react-flexbox-grid';
import Button from 'react-md/lib/Buttons/Button';
import TextField from 'react-md/lib/TextFields';
import Divider from 'react-md/lib/Dividers';
import Moment from 'react-moment';
import DatePicker from 'react-md/lib/Pickers/DatePickerContainer';

import Helper from 'util/Helper';
import EventService from 'service/event/EventService';

import ChecklistValueService from '../services/ChecklistValueService';
import ParamValueService from 'modules/ops/modules/patients/modules/params/services/ParamValueService';
import ReportingService from '../services/ReportingService';

import '../styles/tracking-day.css';

class TrackingDay extends React.Component {
  constructor(props) {
    super(props);

    this.helper = new Helper();

    this.checklistValueService = new ChecklistValueService();
    this.paramValueService = new ParamValueService();
    this.reportingService = new ReportingService();

    this.state = {
      dueDate: props.dueDate || new Date().getTime(),
      patientId: props.patientId || '',
      doctorId: props.doctorId || '',
      params: props.params || [ ],
      checklists: props.checklists || [ ],
      checklistValues: [ ],
      paramValues: [ ],
      checklistValueFields: { },
      paramValueFields: { },
      reportingField: { content: '' },
      reportingFieldHistory: { content: '' },
      hasReportingChanged: false,
      activeParamId: '',
      activeChecklistId: '',
      isDatePickerVisible: false,
      confirmationMessage: 'Are you sure you want to leave without saving the reporting?'
    };

    this._initializeEventListeners();
  }

  componentDidMount() {
    if (this.state.patientId && this.state.doctorId) {
      //this._fetchParamValues();
      //this._fetchChecklistValues();
      //this._fetchReportingValues();
    }
  }

  componentWillUnmount() {
    this._removeUnloadCheck();
  }

  componentWillReceiveProps(props) {
    if (props) {
      let patientDoctorNotPresent = !this.state.patientId && !this.state.doctorId;
      let isFirstTime = this.state.params.length === 0 || this.state.checklists.length === 0;
      let dueDateChanged = !(this.state.dueDate === props.dueDate);
      let dueDate = this.state.dueDate === props.dueDate ? this.state.dueDate : (props.dueDate || new Date().getTime());
      this.setState({
        dueDate: dueDate,
        patientId: props.patientId || '',
        doctorId: props.doctorId || '',
        params: props.params || [ ],
        checklists: props.checklists || [ ],
      }, () => {
        if (isFirstTime || dueDateChanged) {
          this._fetchParamValues();
          this._fetchChecklistValues();
          this._fetchReportingValues();
        }
      });
    }
  }

  render() {
    const paramValueFields = this.state.params.map(({ id }) => (
      <div key={ id }
        className={ 'item-param ' + (id === this.state.activeParamId ? 'highlight' : '')}>
        <TextField className="field-inline"
          id={ 'paramValues-' + id }
          name={ 'paramValues-' + id }
          placeholder="Enter a value"
          type="text"
          block
          paddedBlock
          value={ this.state.paramValueFields[id] }
          onChange={ (value, proxy) => this._syncState(value, proxy, id, 'text', 'param') }
          onFocus={ () => this._onParamFocus(id) }
          onKeyDown={ (event) => this._onParamKeyDown(event, id, 'text') }/>
      </div>
    ));

    const checklistValueFields = this.state.checklists.map(({ id, master }) => (
      <div key={ id }
        className={ 'item-checklist ' + (id === this.state.activeChecklistId ? 'highlight' : '')}>
        <TextField className="field-inline"
          id={ 'checklistValues-' + id }
          name={ 'checklistValues-' + id }
          placeholder="Add value"
          type={ master.type === 1 ? 'number' : 'text' }
          block
          paddedBlock
          value={ this.state.checklistValueFields[id] }
          onChange={ (value, proxy) => this._syncState(value, proxy, id, master.type === 1 ? 'number' : 'text', 'checklist') }
          onFocus={ () => this._onChecklistFocus(id) }
          onKeyDown={ (event) => this._onChecklistKeyDown(event, id, master.type === 1 ? 'number' : 'text') }/>
      </div>
    ));

    const confirmationMessage = this.state.confirmationMessage;

    const reportingField =
      <div
        className={ 'item-reporting ' + (this.state.isReportingActive ? 'highlight' : '') + ' ' + (this.state.hasReportingChanged ? 'pending-save' : '')}>
        <TextField className="field-inline"
          id={ 'reporting' }
          name={ 'reporting' }
          placeholder="Add Reporting"
          type={ 'text' }
          block
          paddedBlock
          rows={ 5 }
          value={ this.state.reportingField.content }
          onChange={ (value, proxy) => this._syncState(value, proxy, null, 'text', 'reporting') }
          onFocus={ () => this._onReportingFocus() }
          onKeyDown={ (event) => this._onReportingKeyDown(event) }/>
        { this.state.hasReportingChanged &&
          <p>
            Press <b>Enter &crarr;</b> to save
          </p>
        }
        <Prompt
          when={ this.state.hasReportingChanged }
          message={() => (
            confirmationMessage
          )}
        />
      </div>;

    return(
      <Col
        xs={12}
        sm={12}
        md={2}
        lg={2}>
        <div className="md-paper md-paper--1 sec-patient-tracking-info">
          <h2 className="title-tracking-day">
            <Moment format="MMM-DD-YYYY">{ this.state.dueDate }</Moment>
            { false &&
              <Button
                icon
                tooltipLabel="Change Date"
                onClick={ this._showDatePicker }>
                date_range
              </Button>
            }
            { false &&
              <DatePicker
                id="dueDate"
                label="For Date"
                displayMode="portrait"
                className={ !this.state.isDatePickerVisible ? 'field-hidden-date' : '' }
                required
                visible={ this.state.isDatePickerVisible }
                maxDate={ new Date() }
                value={ new Date(this.state.dueDate) }
                onVisibilityChange={ this._handleVisibilityChange }
                onChange={ (value, valueDate, proxy) => this._changeDueDate(value, valueDate, proxy) }/>
            }
          </h2>
          <h3 className="value-param">
            <Moment format="dddd">{ this.state.dueDate }</Moment>
          </h3>
          &nbsp;
          <hr/>
          <h4>
            Parameters
          </h4>
          { paramValueFields }
          <hr/>
          { this.state.checklists.length > 0 && false &&
            <div>
              <h4>
                Checklist
              </h4>
              { checklistValueFields }
              <hr/>
            </div>
          }
          <h4>
            Reporting
          </h4>
          { reportingField }
        </div>
      </Col>
    );
  }

  _initializeEventListeners = () => {
    EventService.on('onSelectParamField', (args) => {
      if (args.paramId) {
        this.setState({
          activeParamId: args.paramId
        });
      }
    });

    EventService.on('onSelectChecklistField', (args) => {
      if (args.checklistId) {
        this.setState({
          activeChecklistId: args.checklistId
        });
      }
    });

    EventService.on('onSelectReportingField', (args) => {
      this.setState({
        isReportingActive: false
      });
    });
  }

  _addUnloadCheck = () => {
    window.addEventListener("beforeunload", this._onUnload);
  }

  _removeUnloadCheck = () => {
    window.removeEventListener("beforeunload", this._onUnload);
  }

  _getParamValue = (paramId) => {
    let paramValue = this.helper.findObjectByKeyValue(this.state.paramValues, 'param_id', paramId);
    let value = paramValue.value_num ? paramValue.value_num : paramValue.value_text;

    return value;
  }

  _getChecklistValue = (checklistId, checklistMaster) => {
    let checklistValue = this.helper.findObjectByKeyValue(this.state.checklistValues, 'checklist_id', checklistId);
    let value = '';

    // 1: NUMBER
    // 2: TEXT
    if (checklistMaster.type === 1) {
      value = parseFloat(checklistValue.value_num);
    } else {
      value = checklistValue.value_text;
    }

    return value;
  }

  _showDatePicker = () => {
    this.setState({
      isDatePickerVisible: true
    });
  }

  _handleVisibilityChange = (visible) => {
    this.setState({ isDatePickerVisible: visible });
  }

  _changeDueDate = (value, valueDate, proxy) => {
    this.setState({
      dueDate: new Date(valueDate).getTime()
    }, () => {
      this._fetchParamValues();
      this._fetchChecklistValues();
    });
  }

  _onParamFocus = (paramId) => {
    EventService.emit('onSelectParamField', {
      paramId: paramId
    });
  }

  _onChecklistFocus = (checklistId) => {
    EventService.emit('onSelectChecklistField', {
      checklistId: checklistId
    });
  }

  _onReportingFocus = () => {
    EventService.emit('onSelectReportingField', {});
  }

  _onParamKeyDown = (event, paramId, type) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      let paramValue = this.state.paramValueFields[paramId];
      if (paramValue) {
        let paramValueRequest = { };
        paramValueRequest.param_id = paramId;
        paramValueRequest.patient_id = this.state.patientId;

        if (isNaN(parseFloat(paramValue))) {
          paramValueRequest.value_text = paramValue;
        } else {
          paramValueRequest.value_num = parseFloat(paramValue);
        }

        let paramValueObject = this.helper.findObjectByKeyValue(this.state.paramValues, 'param_id', paramId);
        if (!this.helper.isEmptyObject(paramValueObject)) {
          if (paramValueObject.id) {
            paramValueRequest.id = paramValueObject.id;
          }
          paramValueRequest.measured_on = this.state.dueDate;
          this._updateParamValue(paramValueObject.id, paramValueRequest);
        } else {
          paramValueRequest.measured_on = this.state.dueDate;
          this._addParamValue(paramValueRequest);
        }
      } else {
        EventService.emit('showError', { message: 'Enter a valid value!' });
      }
    }
  }

  _onChecklistKeyDown = (event, checklistId, type) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      let checklistValue = this.state.checklistValueFields[checklistId];
      if (checklistValue) {
        let checklistValueRequest = { };
        checklistValueRequest.checklist_id = checklistId;
        checklistValueRequest.patient_id = this.state.patientId;
        checklistValueRequest.doctor_id = this.state.doctorId;

        if (type === 'text') {
          checklistValueRequest.value_text = checklistValue;
        } else {
          checklistValueRequest.value_num = parseFloat(checklistValue);
        }

        let checklistValueObject = this.helper.findObjectByKeyValue(this.state.checklistValues, 'checklist_id', checklistId);

        if (!this.helper.isEmptyObject(checklistValueObject)) {
          if (checklistValueObject.id) {
            checklistValueRequest.id = checklistValueObject.id;
          }
          checklistValueRequest.due_date = this.state.dueDate;
          this._updateChecklistValue(checklistValueObject.id, checklistValueRequest);
        } else {
          checklistValueRequest.due_date = this.state.dueDate;
          this._addChecklistValue(checklistValueRequest);
        }
      } else {
        EventService.emit('showError', { message: 'Enter a valid value!' });
      }
    }
  }

  _onReportingKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab' || (event.ctrlKey && event.key === 's')) {
      let reportingValue = this.state.reportingField.content;
      if (reportingValue) {
        let reportingValueRequest = { };
        reportingValueRequest.patientId = this.state.patientId;
        reportingValueRequest.content = reportingValue;

        if (this.state.reportingField.id) {
          reportingValueRequest.id = this.state.reportingField.id;
          reportingValueRequest.dueDate = this.state.reportingField.dueDate;
          this._updateReporting(reportingValueRequest);
        } else {
          reportingValueRequest.dueDate = this.state.dueDate;
          this._addReporting(reportingValueRequest);
        }
      } else {
        EventService.emit('showError', { message: 'Enter a valid value!' });
      }
    }
  }

  _syncState = (value, proxy, id, type, source) => {
    let event = proxy.nativeEvent;
    let paramValueFields = this.state.paramValueFields;
    let checklistValueFields = this.state.checklistValueFields;
    let reportingField = this.state.reportingField;
    let reportingFieldHistory = this.state.reportingFieldHistory;
    let hasReportingChanged = this.state.hasReportingChanged;

    switch (source) {
      case 'param':
        let paramValue = type === 'number' ? parseFloat(value) : value;
        paramValueFields[id] = paramValue;
        this.setState({
          paramValueFields: paramValueFields
        });
        break;
      case 'checklist':
        let checklistValue = type === 'number' ? parseFloat(value) : value;
        checklistValueFields[id] = checklistValue;
        this.setState({
          checklistValueFields: checklistValueFields
        });
        break;
      case 'reporting':
        if (!hasReportingChanged) {
          const historyContent = reportingField.content
          reportingFieldHistory.content = historyContent;
          hasReportingChanged = true;
          this._addUnloadCheck();
        } else if (reportingFieldHistory.content === value) {
          hasReportingChanged = false;
          this._removeUnloadCheck();
        }

        reportingField.content = value;

        this.setState({
          reportingField: reportingField,
          reportingFieldHistory: reportingFieldHistory,
          hasReportingChanged: hasReportingChanged
        });
        break;
      default:
        break;
    }
  }

  _onUnload = (e) => {
    e.returnValue = this.state.confirmationMessage;
    return this.state.confirmationMessage;
  }

  _fetchParamValues = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this.setState({
        paramValues: data
      }, () => {
        let paramValueFields = { };
        this.state.params.forEach(({ id }) => {
          paramValueFields[id] = this._getParamValue(id) || '';
        });

        this.setState({
          paramValueFields: paramValueFields
        });
      });

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.paramValueService.getAllByPatient(this.state.patientId, this.state.dueDate, onResponse, onError);
  }

  _fetchChecklistValues = () => {
    // stop loading
    this.helper.stopLoading();

    let onResponse = (data) => {
      this.setState({
        checklistValues: data
      }, () => {
        let checklistValueFields = { };
        this.state.checklists.forEach(({ id, master }) => {
          checklistValueFields[id] = this._getChecklistValue(id, master) || '';
        });

        this.setState({
          checklistValueFields: checklistValueFields
        });
      });

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.checklistValueService.getByPatientDoctor(this.state.patientId, this.state.doctorId, this.state.dueDate, onResponse, onError);
  }

  _fetchReportingValues = () => {
    // stop loading
    this.helper.stopLoading();

    let onResponse = (data) => {
      data.content = data.content ? data.content : '';
      this.setState({
        reportingField: data || { content: '' },
      });

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      //EventService.emit('showError', { message: error.message });

      this.setState({
        reportingField: { content: '' },
      });

      // stop loading
      this.helper.stopLoading();
    }

    this.reportingService.getByPatient(this.state.patientId, this.state.dueDate, onResponse, onError);
  }

  _addParamValue = (paramValueRequest) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.paramValueService.new(paramValueRequest, onResponse, onError);
  }

  _updateParamValue = (id, paramValueRequest) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.paramValueService.update(id, paramValueRequest, onResponse, onError);
  }

  _addChecklistValue = (checklistValueRequest) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.checklistValueService.new(checklistValueRequest, onResponse, onError);
  }

  _updateChecklistValue = (id, checklistValueRequest) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.checklistValueService.update(id, checklistValueRequest, onResponse, onError);
  }

  _addReporting = (reportingValueRequest) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      // stop loading
      this.helper.stopLoading();

      let reportingField = this.state.reportingField;
      let reportingFieldHistory = this.state.reportingFieldHistory;
      const historyContent = this.state.reportingField.content;
      reportingFieldHistory.content = historyContent;

      this.setState({
        reportingFieldHistory: reportingFieldHistory,
        hasReportingChanged: false
      });

      this._removeUnloadCheck();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.reportingService.new(reportingValueRequest, onResponse, onError);
  }

  _updateReporting = (reportingValueRequest) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      // stop loading
      this.helper.stopLoading();

      let reportingField = this.state.reportingField;
      let reportingFieldHistory = this.state.reportingFieldHistory;
      const historyContent = this.state.reportingField.content;
      reportingFieldHistory.content = historyContent;

      this.setState({
        reportingFieldHistory: reportingFieldHistory,
        hasReportingChanged: false
      });

      this._removeUnloadCheck();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    }

    this.reportingService.update(reportingValueRequest.id, reportingValueRequest, onResponse, onError);
  }
}

export default TrackingDay;
