import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import Button from "react-md/lib/Buttons/Button";
import Divider from "react-md/lib/Dividers";
import Moment from "react-moment";

import Helper from "util/Helper";
import EventService from "service/event/EventService";

import CallService from "../services/CallService";

import Call from "./Call";
import CallNotes from "./CallNotes";
import CallToDo from "../modules/todo/components/CallToDo";

import "../styles/calls.css";

class Calls extends React.Component {
  constructor(props) {
    super(props);

    this.FILTER_PAST_FORTNIGHT = 1;
    this.FILTER_TODAY = 2;
    this.FILTER_FUTURE_FORTNIGHT = 3;

    this.helper = new Helper();

    this.callService = new CallService();
    this.state = {
      patient: props.patient,
      patientId: props.patientId || null,
      patientPhoneNumber: props.patientPhoneNumber || "",
      doctorPhoneNumber: props.doctorPhoneNumber || "",
      allDoctors: props.allDoctors || "",
      calls: [],
      sortedCalls: [],
      selectedCallId: "",
      createMode: false,
      filter: this.FILTER_TODAY,
      readOnly: props.readOnly,
      planList: props.planList || [],
    };
  }

  componentWillReceiveProps(props) {
    if (props) {
      let shouldFetch =
        props.patientPhoneNumber !== this.state.patientPhoneNumber;

      this.setState(
        {
          patient: props.patient,
          patientId: props.patientId,
          doctorId: props.doctorId,
          patientPhoneNumber: props.patientPhoneNumber || "",
          doctorPhoneNumber: props.doctorPhoneNumber || "",
          allDoctors: props.allDoctors || "",
          call: {
            // caller: props.doctorPhoneNumber || "",
            // callee: props.patientPhoneNumber || "",
            patientId: props.patientId,
            doctorId: props.doctorId,
            plan: "",
            isCaregiver: false,
            dueDate: new Date().getTime(),
            startTime: new Date().getTime(),
            due_date: new Date().getTime(),
            start_time: new Date().getTime(),
          },
        },
        () => {
          if (shouldFetch) {
            this._fetchCallsByCallee();
          }
        }
      );
    }
  }

  render() {
    const { calls, sortedCalls, planList } = this.state;

    const callList = sortedCalls.map(
      ({ id, caller, doctorId, callee, plan, due_date, start_time }, index) => (
        <div key={id}>
          <div
            className={
              "item-call" + (this.state.selectedCallId === id ? " active" : "")
            }
            onClick={() => this._selectCall(id)}
          >
            <p>{this._getCallerName(doctorId)}</p>
            <p>
              <Moment format="DD MMM YYYY">{due_date}</Moment>
              &nbsp;
              <Moment format="hh:mm a">{start_time}</Moment>
            </p>
          </div>
          {index + 1 !== calls.length && <Divider />}
        </div>
      )
    );

    return (
      <div>
        <Row start="xs">
          <Col
            xs={12}
            sm={12}
            md={this.props.navigo ? 12 : 5}
            lg={this.props.navigo ? 12 : 5}
          >
            <div className="md-paper md-paper--1 container-call-plan">
              <h2>Calls</h2>
              <Row start="xs">
                <Col xs={3}>
                  <Button
                    flat
                    label="past 15"
                    primary={
                      this.state.filter === this.FILTER_PAST_FORTNIGHT
                        ? true
                        : false
                    }
                    onClick={() => this._filter(this.FILTER_PAST_FORTNIGHT)}
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    flat
                    label="today"
                    primary={
                      this.state.filter === this.FILTER_TODAY ? true : false
                    }
                    onClick={() => this._filter(this.FILTER_TODAY)}
                  />
                </Col>
                <Col xs={3}>
                  <Button
                    flat
                    label="next 15"
                    primary={
                      this.state.filter === this.FILTER_FUTURE_FORTNIGHT
                        ? true
                        : false
                    }
                    onClick={() => this._filter(this.FILTER_FUTURE_FORTNIGHT)}
                  />
                </Col>
              </Row>
              <hr />
              {callList}
              <hr />
              {!this.state.createMode && !this.state.readOnly && (
                <div>
                  <Button
                    raised
                    primary
                    label="Add a call"
                    onClick={() => this._onClickAddCall()}
                  >
                    add
                  </Button>
                </div>
              )}
              {this.state.createMode && (
                <Call
                  navigo={this.props.navigo}
                  call={this.state.call}
                  createMode={this.state.createMode}
                  _fetchCalls={this._fetchCallsByCallee}
                />
              )}
            </div>
          </Col>
          {this.state.selectedCallId && (
            <Col xs={12} sm={12} md={7} lg={7}>
              <CallNotes
                planList={planList}
                patient={this.state.patient}
                callId={this.state.selectedCallId}
                call={this.helper.findById(
                  this.state.calls,
                  this.state.selectedCallId
                )}
                callNotes={
                  this.helper.findById(
                    this.state.calls,
                    this.state.selectedCallId
                  ).callNotes
                }
                doctorPhoneNumber={this.state.doctorPhoneNumber}
                callerName={this._getCallerName(
                  this.helper.findById(
                    this.state.calls,
                    this.state.selectedCallId
                  ).doctorId
                )}
                _fetchCallsByCallee={this._fetchCallsByCallee}
                patientId={this.state.patientId}
                doctorId={this.state.doctorId}
              />
            </Col>
          )}

          {this.helper.findById(this.state.calls, this.state.selectedCallId)
            .caller === this.state.doctorPhoneNumber &&
            this.state.selectedCallId && (
              <Col xs={12} sm={12} md={4} lg={4}>
                <CallToDo
                  callId={this.state.selectedCallId}
                  callToDos={
                    this.helper.findById(
                      this.state.calls,
                      this.state.selectedCallId
                    ).todos || []
                  }
                />
              </Col>
            )}
        </Row>
      </div>
    );
  }

  _getCallerName = (caller) => {
    return caller === this.state.doctorId
      ? "You"
      : "Dr " +
          this.helper.findObjectByKeyValue(this.state.allDoctors, "id", caller)
            .name;
  };

  _selectCall = (id) => {
    this.setState({
      selectedCallId: id,
    });
  };

  _onClickAddCall = () => {
    const { doctorPhoneNumber } = this.state;
    if (doctorPhoneNumber && doctorPhoneNumber !== "") {
      this.setState({
        createMode: true,
      });
    } else {
      EventService.emit("showError", { message: "Select Doctor" });
    }
  };

  _filter = (filterType) => {
    const calls = this.state.calls;
    let sortedCalls = this.state.sortedCalls;

    switch (filterType) {
      case this.FILTER_PAST_FORTNIGHT:
        sortedCalls = calls.filter((call) => {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let pastFortnight = new Date(today.getTime());
          pastFortnight.setDate(today.getDate() - 15);

          if (
            call.due_date < today.getTime() &&
            call.due_date >= pastFortnight.getTime()
          ) {
            return true;
          }
        });
        break;
      case this.FILTER_TODAY:
        sortedCalls = calls.filter((call) => {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let tomorrow = new Date(today.getTime());
          tomorrow.setDate(today.getDate() + 1);

          if (
            call.due_date >= today.getTime() &&
            call.due_date < tomorrow.getTime()
          ) {
            return true;
          }
        });
        break;
      case this.FILTER_FUTURE_FORTNIGHT:
        sortedCalls = calls.filter((call) => {
          let today = new Date();
          today.setHours(0, 0, 0, 0);
          let tomorrow = new Date(today.getTime());
          tomorrow.setDate(today.getDate() + 1);
          let futureFortnight = new Date(today.getTime());
          futureFortnight.setDate(today.getDate() + 15);

          if (
            call.due_date >= tomorrow.getTime() &&
            call.due_date < futureFortnight.getTime()
          ) {
            return true;
          }
        });
        sortedCalls.reverse();
        break;
      default:
        break;
    }

    this.setState({
      filter: filterType,
      sortedCalls: sortedCalls,
    });
  };

  _fetchCallsByCallee = (preferredSelectedCallId) => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this.setState(
        {
          calls: data,
          selectedCallId: preferredSelectedCallId || "",
          createMode: false,
        },
        () => {
          if (!preferredSelectedCallId) {
            this._filter(this.FILTER_TODAY);
          }
        }
      );

      // stop loading
      this.helper.stopLoading();
    };

    let onError = (error) => {
      //EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    };

    if (this.state.patientPhoneNumber) {
      let fromDate = new Date();
      fromDate.setDate(fromDate.getDate() - 15);
      fromDate.setHours(0, 0, 0, 0);

      let toDate = new Date();
      toDate.setDate(toDate.getDate() + 15);
      toDate.setHours(0, 0, 0, 0);

      this.callService.getCallPlanByCallee(
        this.state.patientId,
        fromDate.getTime(),
        toDate.getTime(),
        onResponse,
        onError
      );
    } else {
      //EventService.emit('showError', { message: 'Not a valid Patient profile' });
    }
  };
}

export default Calls;
