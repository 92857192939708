import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import Button from 'react-md/lib/Buttons/Button';
import Divider from 'react-md/lib/Dividers';
import Moment from 'react-moment';
import TextField from 'react-md/lib/TextFields';
import FontIcon from 'react-md/lib/FontIcons';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Helper from 'util/Helper';
import EventService from 'service/event/EventService';

import MedicationService from '../services/MedicationService';

import '../styles/medications.css';

class Medications extends React.Component {
  constructor(props) {
    super(props);

    this.helper = new Helper();

    this.medicationService = new MedicationService();

    this.state = {
      patientId: props.patientId || '',
      doctorId: props.doctorId || '',
      medications: props.medications || [ ],
      medication: {
        patientId: props.patientId || '',
        doctorId: props.doctorId || '',
        content: props.content || ''
      },
      error: {
        content: ''
      },
      editorState: EditorState.createEmpty(),
      createMode: false
    }
  }

  componentWillReceiveProps(props) {
    if (props) {
      this.setState({
        patientId: props.patientId || '',
        doctorId: props.doctorId || '',
        medications: props.medications || [ ]
      });
    }
  }

  render() {
    const medications = this.state.medications.map(({ id, content, updatedAt }, index) => (
      <div key={ id }
        className="item-medication">
          <p>
            <FontIcon>event</FontIcon>
            &nbsp;
            <Moment format="MMM DD YYYY hh:mm a">{ updatedAt }</Moment>
          </p>
          <p
            className="content-medication"
            dangerouslySetInnerHTML={ this.helper.createMarkup(content) }/>
      </div>
    ));

    return(
      <div className="container-call-plan">
        <Row start="xs" className="container-call-plan">
          <Col className="container-call-plan"
            xs={12}>
            <div >
              <h2>
                { 'Medications' }
              </h2>
              <div className='medication-notes'>
                {medications}
              </div>
              { !this.state.createMode && this.state.doctorId &&
                <Button className="btn-action-call"
                  raised
                  primary
                  label="Add Medications"
                  onClick={ () => this._onClickAddMedication() }>
                  add
                </Button>
              }
              { this.state.createMode &&
                <div>
                  <Editor
                  className="container-editor"
                    editorState={ this.state.editorState }
                    wrapperClassName=""
                    editorClassName=""
                    hashtag={{
                      separator: ' ',
                      trigger: '#',
                    }}
                    onEditorStateChange={ this._onEditorStateChange }/>
                  <Button className="btn-action-call"
                    raised
                    primary
                    label="Save"
                    onClick={ () => this._onClickSaveMedication() }>
                    save
                  </Button>
                </div>
              }
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  _onEditorStateChange = (editorState) => {
    let medication = this.state.medication;

    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    medication.content = content;

    this.setState({
      editorState,
      medication
    });
  }

  _onClickAddMedication = () => {
    this.setState({
      createMode: true
    });
  }

  _onClickSaveMedication = () => {
    this._handleSubmit();
  }

  _handleSubmit = () => {
    if (this.state.medication.content) {
      this._addMedication();
    } else {
      EventService.emit('showError', { message: 'Enter a valid content!' });
    }
  }

  _addMedication = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      let medications = this.state.medications;
      medications.push(data);

      this.setState({
        createMode: false,
        medications: medications
      });

      // stop loading
      this.helper.stopLoading();
    }

    let onError = (error) => {
      EventService.emit('showError', { message: error.message });

      // stop loading
      this.helper.stopLoading();
    };

    // pre-request parsing
    let medicationRequest = { };
    medicationRequest.patientId = this.state.patientId;
    medicationRequest.doctorId = this.state.doctorId;
    medicationRequest.content = this.state.medication.content;

    this.medicationService.new(medicationRequest, onResponse, onError);
  }
}

export default Medications;
