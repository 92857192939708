import AuthenticationService from 'service/auth/AuthenticationService';
import EnvironmentService from 'service/environment/EnvironmentService';
import APIConfig from 'service/network/APIConfig';
import APIService from 'service/network/APIService';

class MedicationService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(medication, onResponse, onError) {
    let url = APIConfig.medication.new;

    return this.apiService.post(url, medication, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.medication.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.medication.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, medication, onResponse, onError) {
    let url = APIConfig.medication.update.format(id);

    return this.apiService.put(url, medication, onResponse, onError);
  }
}

export default MedicationService;
