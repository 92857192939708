import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import FontIcon from 'react-md/lib/FontIcons';
import { ListItem, ListItemControl } from 'react-md/lib/Lists';
import Checkbox from 'react-md/lib/SelectionControls/Checkbox';
import Card from 'react-md/lib/Cards/Card';

import '../styles/call-todo-item.css';

class CallToDoItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id || '',
      done: props.done || false,
      name: props.name || '',
      call_id: props.call_id || ''
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      id: props.id || '',
      done: props.done || false,
      name: props.name || '',
      call_id: props.call_id || ''
    });
  }

  render() {
    return(
      <Col
        xs={12}>
        <div className="card-call-todo">
          <Checkbox
            id={ 'key' + this.state.name }
            name="done"
            label={ this.state.done ? <strike>{ this.state.name }</strike> : this.state.name }
            defaultChecked={ this.state.done }
            onChange={ this._syncState }/>
        </div>
      </Col>
    );
  }

  _syncState = (value, proxy) => {
    let event = proxy.nativeEvent;
    let done = this.state.done;

    switch (event.target.name) {
      case 'done':
        done = value || false;
        this.setState({
          done: done
        }, () => {
          this.props._updateCallToDo({
            id: this.state.id,
            done: this.state.done,
            name: this.state.name,
            call_id: this.state.call_id
          });
        });
        break;
      default:
        break;
    }
  }
}

export default CallToDoItem;
