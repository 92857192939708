/**
  * 1: Female
  * 2: Male
  */
const Gender = {
  FEMALE: 1,
  MALE: 2
};

export default Gender;
