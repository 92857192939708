import StorageService from "service/storage/StorageService";
import Config from "./EnvironmentConfig";

class EnvironmentService {
  constructor(environment) {
    this.storageService = new StorageService();
    this.config = Config;

    if (
      window.location.hostname === "bridge.prod.zyla.in" ||
      window.location.hostname === "bridge.zyla.in" ||
      window.location.hostname === "sy-bridge.zyla.in"
    ) {
      this.environment = this.storageService.get("ENVIRONMENT") || "production";
    } else {
      this.environment =
        this.storageService.get("ENVIRONMENT") || "development";
    }
  }

  get() {
    return this.environment;
  }

  set(environment) {
    this.environment = environment;
    this.storageService.set("ENVIRONMENT", this.environment);
  }

  isProduction() {
    return this.environment === this.config.environments.production;
  }

  getBaseAPI() {
    return this.config.vars[this.environment].apiUrl;
  }

  getServiceAPI() {
    return this.config.vars[this.environment].serviceUrl;
  }
  getServiceAPINew() {
    return this.config.vars[this.environment].serviceNew;
  }

  getServiceSocket() {
    return this.config.vars[this.environment].serviceSocket;
  }

  getDomain() {
    return this.config.vars[this.environment].domain;
  }

  getLocalService() {
    return this.config.vars[this.environment].localService;
  }
}

export default EnvironmentService;
