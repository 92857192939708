import AuthenticationService from 'service/auth/AuthenticationService';
import EnvironmentService from 'service/environment/EnvironmentService';
import APIConfig from 'service/network/APIConfig';
import APIService from 'service/network/APIService';

class ParamValueService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(paramValue, onResponse, onError) {
    let url = APIConfig.paramValue.new;

    return this.apiService.post(url, paramValue, onResponse, onError);
  }

  getAllByPatient(patientId, dueDate, onResponse, onError) {
    let url = APIConfig.paramValue.getAllByPatient.format(patientId, dueDate);

    return this.apiService.get(url, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.paramValue.get + id;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, paramValue, onResponse, onError) {
    let url = APIConfig.paramValue.update + id + '/update/value';

    return this.apiService.put(url, paramValue, onResponse, onError);
  }
}

export default ParamValueService;
