import React from "react";
import PropTypes from "prop-types";
import Snackbar from "react-md/lib/Snackbars";

import EventService from "service/event/EventService";

class GlobalError extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toasts: [],
      autohide: true,
    };

    this._addToast = this._addToast.bind(this);
    this._removeToast = this._removeToast.bind(this);
  }

  componentDidMount() {
    let that = this;
    EventService.on("showError", (params) => {
      that._addToast(
        params.message || "Looks like something went wrong. Please try again.",
        params.action || ""
      );
    });
    EventService.on("onSuccess", (params) => {
      that._addToast((params && params.message) || "Operation was successful!");
    });
  }

  componentWillUpdate(nextProps, nextState) {
    const { toasts } = nextState;
    const [toast] = toasts;
    if (this.state.toasts === toasts || !toast) {
      return;
    }

    const autohide = toast.action !== "Retry";
    this.setState({ autohide });
  }

  _addToast(text, action) {
    const toasts = this.state.toasts.slice();
    toasts.push({ text, action });

    this.setState({ toasts });
  }

  _removeToast() {
    const [, ...toasts] = this.state.toasts;
    this.setState({ toasts });
  }

  render() {
    return (
      <Snackbar
        {...this.state}
        onDismiss={this._removeToast}
        style={{ zIndex: "20000000000" }}
      />
    );
  }
}

export default GlobalError;
