import AuthenticationService from 'service/auth/AuthenticationService';
import EnvironmentService from 'service/environment/EnvironmentService';
import APIConfig from 'service/network/APIConfig';
import APIService from 'service/network/APIService';

class ChecklistValueService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  new(checklistValue, onResponse, onError) {
    let url = APIConfig.checklistValue.new;

    return this.apiService.post(url, checklistValue, onResponse, onError);
  }

  get(id, onResponse, onError) {
    let url = APIConfig.checklistValue.get.format(id);

    return this.apiService.get(url, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.checklistValue.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  update(id, checklistValue, onResponse, onError) {
    let url = APIConfig.checklistValue.update.format(id);

    return this.apiService.put(url, checklistValue, onResponse, onError);
  }

  getByPatientDoctor(patientId, doctorId, dueDate, onResponse, onError) {
    let url = APIConfig.checklistValue.getByPatientDoctor.format(patientId, doctorId, dueDate);

    return this.apiService.get(url, onResponse, onError);
  }
}

export default ChecklistValueService;
