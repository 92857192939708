import React from "react";
import { Grid, Row, Col } from "react-flexbox-grid";
import DatePicker from "react-md/lib/Pickers/DatePickerContainer";
import TimePicker from "react-md/lib/Pickers/TimePickerContainer";
import TextField from "react-md/lib/TextFields";
import FontIcon from "react-md/lib/FontIcons";
import Button from "react-md/lib/Buttons/Button";
import Moment from "react-moment";
import Collapse from "react-md/lib/Helpers/Collapse";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Helper from "util/Helper";
import EventService from "service/event/EventService";

import CallService from "../services/CallService";

import "../styles/call.css";
import AuthenticationService from "../../../../../../../../../service/auth/AuthenticationService";
import PatientProgressNotesService from "../../../../new-bridge/services/PatientProgressNotesService";

class Call extends React.Component {
  constructor(props) {
    super(props);

    this.helper = new Helper();

    this.callService = new CallService();
    this.authenticationService = new AuthenticationService();
    this.patientProgressNotesService = new PatientProgressNotesService();

    let call;
    if (props.call) {
      call = props.call;
      call.dueDate = call.due_date;
      call.startTime = call.start_time;
    }

    let editorState;
    if (call && call.plan) {
      const contentBlock = htmlToDraft(call.plan);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        editorState = EditorState.createWithContent(contentState);
      }
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      call: call || {
        patientId: "",
        doctorId: "",
        plan: "",
        isCaregiver: false,
        dueDate: new Date().getTime(),
        startTime: new Date().getTime(),
      },
      error: {
        dueDate: "",
        startTime: "",
        plan: "",
      },
      editorState: editorState,
      createMode: props.createMode,
      editMode: props.editMode,
      collapsed: false,
      callerName: props.callerName,
    };

    const employeeProfile =
      this.authenticationService.getUserProfile().employeeProfile;

    this.employeeName = employeeProfile.firstName;
    this.employeeId = employeeProfile.id;
  }

  componentDidMount() {
    // nothing
  }

  componentWillReceiveProps(props) {
    if (props) {
      let call;
      if (props.call) {
        call = props.call;
        call.dueDate = call.due_date;
        call.startTime = call.start_time;
      }

      let editorState;
      if (call.plan) {
        const contentBlock = htmlToDraft(call.plan);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          editorState = EditorState.createWithContent(contentState);
        }
      } else {
        editorState = EditorState.createEmpty();
      }

      this.setState({
        call: call || {
          patientId: "",
          doctorId: "",
          plan: "",
          isCaregiver: false,
          dueDate: new Date().getTime(),
          startTime: new Date().getTime(),
        },
        createMode: props.createMode,
        editMode: props.editMode,
        editorState: editorState,
        callerName: props.callerName,
      });
    }
  }

  render() {
    const { collapsed } = this.state;

    return (
      <Col xs={12}>
        {!this.state.createMode && !this.state.editMode && (
          <div>
            <div className="sec-call-info">
              <Row>
                {this.state.callerName && (
                  <Col xs={10}>
                    <p>{this.state.callerName}</p>
                    <p>
                      <Moment format="DD MMM YYYY">
                        {this.state.call.due_date}
                      </Moment>
                      &nbsp;
                      <Moment format="hh:mm a">
                        {this.state.call.start_time}
                      </Moment>
                    </p>
                  </Col>
                )}
                {!this.state.callerName && (
                  <Col xs={10}>
                    <span>From: {this.state.call.doctorId}</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <span>To: {this.state.call.patientId}</span>
                  </Col>
                )}
                <Col xs={2}>
                  <Button
                    icon
                    tooltipLabel="Edit"
                    onClick={() => this._onClickEditCall()}
                  >
                    edit
                  </Button>
                </Col>
                <Col xs={12}>
                  <Moment format="DD MMM YYYY">
                    {this.state.call.due_date}
                  </Moment>
                  &nbsp;
                  <Moment format="hh:mm a">{this.state.call.start_time}</Moment>
                </Col>
              </Row>
            </div>
            {this.state.call.plan && (
              <div className="sec-call-info">
                <Collapse collapsed={collapsed}>
                  <div>
                    <h5>Plan</h5>
                    <p
                      dangerouslySetInnerHTML={this.helper.createMarkup(
                        this.state.call.plan
                      )}
                    />
                  </div>
                </Collapse>
              </div>
            )}
          </div>
        )}
        {(this.state.createMode || this.state.editMode) && (
          <div className="md-paper md-paper--1 sec-new-call">
            <DatePicker
              id="dueDate"
              label="For Date"
              displayMode="portrait"
              className="date-button-form"
              required
              inline={true}
              minDate={
                this.state.createMode
                  ? new Date(new Date().setDate(new Date().getDate() - 3))
                  : null
              }
              value={new Date(this.state.call.dueDate)}
              error={this.state.error.dueDate ? true : false}
              errorText={this.state.error.dueDate}
              onChange={(value, valueDate, proxy) =>
                this._syncStateDate(value, valueDate, proxy, "dueDate")
              }
            />
            <TimePicker
              id="startTime"
              label="Start Time"
              displayMode="portrait"
              className="date-button-form"
              inline={true}
              required
              value={new Date(this.state.call.startTime)}
              error={this.state.error.startTime ? true : false}
              errorText={this.state.error.startTime}
              onChange={(value, valueDate, proxy) =>
                this._syncStateDate(value, valueDate, proxy, "startTime")
              }
            />
            <br />
            Call Plan:
            <br />
            <Editor
              spellCheck={true}
              editorState={this.state.editorState}
              wrapperClassName=""
              editorClassName=""
              hashtag={{
                separator: " ",
                trigger: "#",
              }}
              onEditorStateChange={this._onEditorStateChange}
            />
            <Button
              className="btn-action-call"
              raised
              primary
              label="Save"
              onClick={() => this._onClickAddCall()}
            >
              save
            </Button>
          </div>
        )}
      </Col>
    );
  }

  _toggleCollapse = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  _onEditorStateChange = (editorState) => {
    let call = this.state.call;

    let plan = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    call.plan = plan;

    this.setState({
      editorState,
      call,
    });
  };

  _syncState = (value, proxy) => {
    let event = proxy.nativeEvent;
    let call = this.state.call;

    switch (event.target.name) {
      case "plan":
        call.plan = value;
        this.setState({
          call: call,
        });
        break;
      default:
        break;
    }
  };

  _syncStateDate = (value, valueDate, proxy, source) => {
    let event = proxy.nativeEvent;
    let call = this.state.call;

    switch (source) {
      case "dueDate":
        call.dueDate = valueDate.getTime();
        this.setState({
          call: call,
        });
        break;
      case "startTime":
        call.startTime = valueDate.getTime();
        this.setState({
          call: call,
        });
        break;
      default:
        break;
    }
  };

  _onClickAddCall = () => {
    this._handleSubmit();
  };

  _onClickEditCall = () => {
    this.setState({
      editMode: true,
      createMode: false,
    });
  };

  _handleSubmit = () => {
    if (this.state.call.dueDate) {
      if (this.state.call.startTime) {
        if (this.state.createMode) {
          this._addCall();
        } else {
          this._updateCall();
        }
      } else {
        this.setState({
          error: {
            startTime: "Please enter a valid start time!",
          },
        });
      }
    } else {
      this.setState({
        error: {
          dueDate: "Please enter a valid due date!",
        },
      });
    }
  };

  _addCall = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this.setState({
        createMode: false,
      });

      // stop loading
      this.helper.stopLoading();

      this.props._fetchCalls();

      EventService.emit("onAddCall", { createMode: false });
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });

      // stop loading
      this.helper.stopLoading();
    };

    // pre-request parsing
    let callRequest = {};
    const { patientId, doctorId, plan, isCaregiver, dueDate, startTime } =
      this.state.call;
    callRequest.patientId = patientId;
    callRequest.doctorId = doctorId;
    callRequest.plan = plan;
    callRequest.is_caregiver = isCaregiver;
    callRequest.due_date = new Date(dueDate).getTime();
    callRequest.start_time = new Date(startTime).getTime();
    callRequest.end_time = null;

    this.callService.new(callRequest, onResponse, onError);

    // create pn if from navigo
    if (this.props.navigo) {
      let progressNoteRequest = {};
      progressNoteRequest.patientId = parseInt(this.state.call.patientId);
      progressNoteRequest.body = this.state.call.plan;
      progressNoteRequest.author = {
        name: this.employeeName,
        employeeId: this.employeeId,
      };
      progressNoteRequest.acknowledgers = [];
      progressNoteRequest.comments = [];
      progressNoteRequest.tag = 1;
      this.patientProgressNotesService.createProgressNote(
        progressNoteRequest,
        onResponse,
        onError
      );
    }
  };

  _updateCall = () => {
    // start loading
    this.helper.startLoading();

    let onResponse = (data) => {
      this.setState({
        updateMode: false,
      });

      // stop loading
      this.helper.stopLoading();

      this.props._fetchCalls();

      EventService.emit("onAddCall", { createMode: false });
    };

    let onError = (error) => {
      EventService.emit("showError", { message: error.message });

      // stop loading
      this.helper.stopLoading();
    };

    // pre-request parsing
    let callRequest = {};
    const { id, patientId, doctorId, plan, isCaregiver, dueDate, startTime } =
      this.state.call;
    callRequest.id = id;
    callRequest.patientId = patientId;
    callRequest.doctorId = doctorId;
    callRequest.plan = plan;
    callRequest.is_caregiver = isCaregiver;
    callRequest.due_date = new Date(dueDate).getTime();
    callRequest.start_time = new Date(startTime).getTime();
    callRequest.end_time = null;

    this.callService.update(id, callRequest, onResponse, onError);
  };
}

export default Call;
