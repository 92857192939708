import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  Switch,
} from "@material-ui/core";
// import { DatePicker } from "react-md";

import { DatePickerContainer } from "react-md/lib/Pickers";
import FontIcon from "react-md/lib/FontIcons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import NavigoAPIServices from "../services/navigoAPIServices";
import AuthenticationService from "service/auth/AuthenticationService";
import debounce from "debounce";
import moment from "moment";
import EventService from "../../../../../service/event/EventService";
import { GetStringSlot } from "../index";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import calendarIcon from "../../../../../assets/icons/plancreationcallender.png";
import { errorMessageHandler } from "../utils/helperMethod";
const intToTime = (t) => {
  let hours = `${parseInt(t / 60)}`;
  let minutes = `${parseInt(t % 60)}`;

  return `${hours.length === 1 ? `0${hours}` : hours}:${
    minutes.length === 1 ? "00" : minutes
  }`;
};

function AppointMentDialog(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState(
    props.isEdit ? props.currentAppointment.patient.email : ""
  );
  const [type, setType] = useState(
    props.isEdit ? props.currentAppointment.appointment.serviceID : ""
  );
  const [clientCode, setClientCode] = useState(
    props.isEdit ? props.currentAppointment.patient.clientCode : ""
  );

  const [appointDate, setAppointDate] = useState(
    props.isEdit
      ? new Date(props.currentAppointment.appointment.appointmentDate)
      : null
  );
  const [timeslot, settimeSlot] = useState(
    props.isEdit
      ? intToTime(props.currentAppointment.appointment.timeSlot)
      : null
  );
  const [currentService, setCurrentService] = useState(
    props.isEdit ? { ...props.currentAppointment.service } : {}
  );
  const [editappointDate, setEditAppointDate] = useState(null);
  const [editTimeSlot, setEditTimeSlot] = useState(null);
  const [isEdit, setIsEdit] = useState(props.isEdit);
  const [loader, setloader] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingSlot, setFetchingSlot] = useState(false);
  const [fetchingSlotEdit, setFetchingSlotEdit] = useState(false);
  const [noSlots, setNoSlots] = useState(true);
  const [slots, setSlots] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [addressSelected, setAddressSelected] = useState({});
  const [addRequired, setAddRequired] = useState(false);
  const [editAddress, setEditAddress] = useState(null);
  const [showEditAddress, setShowEditAddress] = useState(false);
  const [labSelected, setLabSelected] = useState(1);
  const [assignedTo, setAssignedTo] = useState(
    props.isEdit ? props.currentAppointment.appointment.email : ""
  );
  const [disableAssignedTo, setDisableAssignedTo] = useState(true);
  const [assignedToList, setAssignedToList] = useState([]);
  const [vendor, setVendor] = useState("");
  const patient = useRef(null);
  const minDate = useRef(new Date());
  const maxDate = useRef(new Date());

  const navigoAPIServices = new NavigoAPIServices();

  useEffect(() => {
    if (props.isEdit) {
      if (props.currentAppointment?.service?.addressRequired) {
        handleGetAddress(props.currentAppointment?.patient?.id);
      }
      getCalendarList(props.currentAppointment.service.ID);
    }
  }, []);

  const handleNameSearch = useMemo(
    () =>
      debounce((e, name) => {
        if (name && name.length > 0 && typeof parseInt(name) === "number") {
          navigoAPIServices.getPatientDataThroughID(
            name,
            (res) => {
              if (res && res.id !== undefined) {
                setNameList([res]);
              } else {
                setNameList([]);
              }
            },
            (err) => {
              setNameList([]);
            }
          );
        }
      }, 300),
    []
  );
  const handleTimeSlotChange = (e, v) => {
    settimeSlot(v);
  };

  const createAppointment = () => {
    const authenticationService = new AuthenticationService();
    setLoading(true);
    let user = authenticationService.getUserProfile();
    let body = {
      patientID: patient.current.id,
      bookedFor: 0,
      serviceID: type,
      status: 0,
      bookingDate: new Date().toISOString(),
      appointmentDate: new Date(
        moment(appointDate).set("hour", 0).add(12, "h").format()
      ).toISOString(),
      timeSlot: stringToIntTime(timeslot.slot),
      createdBy: user.employeeProfile?.id,
    };
    if (currentService && currentService.addressRequired) {
      body.addressId = addressSelected.id;
    }
    if (currentService && currentService.isLabTest) {
      body.vendorId = vendor;
    }
    if (currentService && currentService.isLabTest) {
      body.timeSlotId = timeslot.key;
    }
    if (assignedTo) {
      body.email = assignedTo;
    }

    navigoAPIServices.createAppointment(
      body,
      (res) => {
        setLoading(false);
        props.closeDialog(true);
      },
      (err) => {
        EventService.emit("showError", {
          message: errorMessageHandler(err, "Cannot create appointment"),
        });
        setLoading(false);
      }
    );
  };
  const handleLabtestAppointmentUpdate = () => {
    setLoading(true);
    let body = {
      addressId: props.currentAppointment.appointment.addressId,
      collectionDate: moment(editappointDate)
        .set("hour", 0)
        .add(12, "h")
        .format("YYYY-MM-DD"),
      externalOrderId: props.currentAppointment.appointment.externalOrderId,
      patientID: props.currentAppointment.appointment.patientID,
      pincode: 0,
      serviceID: props.currentAppointment.appointment.serviceID,
      status: 6,
      timeSlot: stringToIntTime(editTimeSlot.slot),
      timeSlotId: editTimeSlot.key,
    };
    navigoAPIServices.cancelAndRescheduleLabAppointments(
      body,
      (res) => {
        if (res && res.externalOrderId) {
          handleAppointmentUpdate(res.externalOrderId);
        } else {
          EventService.emit("showError", {
            message: "Error while rescheduling the appointment",
          });
          setLoading(false);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: errorMessageHandler(
            err,
            "Error while rescheduling the appointment"
          ),
        });
        setLoading(false);
      }
    );
  };
  const handleAppointmentUpdate = (externalOrderId = null) => {
    setLoading(true);
    const authenticationService = new AuthenticationService();
    let user = authenticationService.getUserProfile();
    let body = {
      appointmentID: props.currentAppointment.appointment.ID,
      appointmentDate: new Date(
        moment(editappointDate).set("hour", 0).add(12, "h").format()
      ).toISOString(),
      timeSlot: stringToIntTime(editTimeSlot.slot),
      timeSlotId: editTimeSlot.key,
      vendor: props.currentAppointment.appointment.vendorId,
      vendorCode: props.currentAppointment.appointment.vendorCode,
      rescheduledBy: user.employeeProfile?.id,
    };
    if (externalOrderId) {
      body.externalOrderId = externalOrderId;
    }
    if (assignedTo) {
      body.email = assignedTo;
    }
    navigoAPIServices.rescheduleAppointments(
      body,
      (res) => {
        setLoading(false);
        if (externalOrderId) {
          props.handleSelectChange(6, props.currentAppointment);
        }

        props.closeDialog(true);
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot update appointment",
        });
        setLoading(false);
      }
    );
  };
  const stringToIntTime = (s) => {
    let tt = s.split(":");
    let h = parseInt(tt[0]);
    let m = parseInt(tt[1]);
    return parseInt(h * 60 + m);
  };

  const getTimeSlots = (date) => {
    try {
      const onResponse = (res) => {
        if (res && res.slot && res.slot.length > 0) {
          let allSlots = res.slot.map((slt) => {
            return { key: slt.key, slot: GetStringSlot(slt.value) };
          });
          setFetchingSlot(false);
          setFetchingSlotEdit(false);
          setSlots([...allSlots]);
          setNoSlots(false);
          setVendor(res.vendorId);
        } else {
          setFetchingSlot(false);
          setFetchingSlotEdit(false);
          setNoSlots(true);
        }
      };
      const onError = (err) => {
        setFetchingSlot(false);
        setFetchingSlotEdit(false);
        setNoSlots(true);
        EventService.emit("showError", {
          message: "Error fetching slots.",
        });
      };

      let body = {
        addressId: addressSelected?.id,
        collectionDate: moment(date).format("YYYY-MM-DD"),
        pincode: addressSelected?.pincode,
        serviceId: type,

        email: assignedTo,
        vendor: "",
      };
      if (props.isEdit) {
        body = {
          ...body,
          addressId: editAddress?.id,
          pincode: editAddress?.pincode,
          patientID: props.currentAppointment.appointment.patientID,
          vendor: props.currentAppointment.appointment.vendorId,
          vendorCode: props.currentAppointment.appointment.vendorCode,
        };
      } else {
        body.patientID = patient.current?.id;
      }

      navigoAPIServices.getLabtestTimeSlot(
        body,
        (res) => {
          if (res && res.slots && res.slots.length > 0) {
            let slotsArr = res.slots.map((slt, index) => {
              return {
                ...slt,
              };
            });
            onResponse({ ...res, slot: slotsArr });
          } else {
            onResponse({});
          }
        },
        (err) => {
          onError(err);
        }
      );

      // else {
      //   navigoAPIServices.getTimeSlots(
      //     type,
      //     assignedTo ? assignedTo : "",
      //     moment(date).format("YYYY-MM-DD"),
      //     (res) => {
      //       if (res && res.slot && res.slot.length > 0) {
      //         let slotsArr = res.slot.map((slt, index) => {
      //           return {
      //             key: index,
      //             value: slt,
      //           };
      //         });
      //         onResponse({ slot: slotsArr });
      //       } else {
      //         onResponse({});
      //       }
      //     },
      //     (err) => {
      //       onError();
      //     }
      //   );
      // }
    } catch (err) {
      EventService.emit("showError", {
        message: "Error fetching slots.",
      });
    }
  };
  const getPatientName = () => {
    let firstName = props.currentAppointment.patient.firstName;
    let lastName = props.currentAppointment.patient.lastName
      ? props.currentAppointment.patient.lastName
      : "";

    return firstName + " " + lastName;
  };
  const getPatientAddress = (addr) => {
    if (addr) {
      return `${addr.line1},${addr.line2} ${
        addr.landmark ? addr.landmark : ""
      } ${addr.city},${addr.state}-${addr.pincode} Phone number- ${
        addr.phoneNo
      }`;
    }
    return "";
  };
  const getMinMaxDates = (val) => {
    let service = props.services.find((ele) => ele.ID == val);

    if (service) {
      let mindays = service.minDayBooking;

      minDate.current = new Date(moment(new Date()).add(mindays, "days"));

      let maxdays = service.maxDayBooking;
      maxDate.current = new Date(moment(new Date()).add(maxdays, "days"));
    } else {
      minDate.current = new Date();
      maxDate.current = new Date();
    }
  };
  const getServiceByPatientId = (id) => {
    navigoAPIServices.getServiceByPatientId(
      id,
      (res) => {
        if (res && res.length > 0) {
          setServiceList([...res]);
        } else {
          setServiceList([]);
          EventService.emit("showError", {
            message: "No services exist for given user",
          });
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Cannot retrieve services",
        });
      }
    );
  };
  const handleGetAddress = (id) => {
    navigoAPIServices.getPrimaryAddress(
      id,
      (res) => {
        if (res && res.length > 0) {
          setAddressList([...res]);

          if (
            props.isEdit &&
            props.currentAppointment?.service.addressRequired &&
            props.currentAppointment?.appointment.addressId
          ) {
            let myAddress = res.find((addr) => {
              return addr.id === props.currentAppointment.appointment.addressId;
            });

            if (myAddress) {
              setEditAddress(myAddress);
              setShowEditAddress(true);
            }
          }
        } else {
          setAddressList([]);
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "No address found.",
        });
      }
    );
  };

  const handleCreateAppointmentClick = () => {
    createAppointment();
  };

  const getCalendarList = (id) => {
    navigoAPIServices.getSingleServiceData(
      id,
      (res) => {
        if (res.calendars && res.calendars.length > 0) {
          setDisableAssignedTo(false);
          setAssignedToList(res.calendars.map((ele) => ele.email));
        }
      },
      (err) => {
        EventService.emit("showError", {
          message: "Error while fetching calendar list",
        });
      }
    );
  };

  return (
    <div className="appointment-dialog-container">
      {!loader && (
        <div className="appointment-dialog-container-main">
          <div className="appointment-dialog-main-top-heading">
            <p>{isEdit ? "Edit appointment" : "Create appointment"}</p>
            <FontIcon
              onClick={() => {
                props.closeDialog(0);
              }}
              className="appointment-dialog-main-close"
            >
              close
            </FontIcon>
          </div>
          <div
            className={`appointment-dialog-main-section-1 common-section-input-class ${
              isEdit ? "disabled-section-class" : ""
            }`}
          >
            <p>Patient Id</p>
            <Autocomplete
              id="appointment-dialog-name"
              className="appointment-dialog-main-section-1-name"
              options={nameList}
              disabled={isEdit}
              onChange={(e, v) => {
                if (v) {
                  v.email && setEmail(v.email);
                  v.clientCode && setClientCode(v.clientCode);
                  patient.current = { ...v };

                  setType("");
                  setCurrentService({});

                  getServiceByPatientId(v.id);
                } else {
                  setEmail("");
                  setClientCode("");
                  patient.current = null;
                  setType("");
                  setCurrentService({});
                  setServiceList([]);
                }
              }}
              getOptionLabel={(option) => {
                return option.firstName + " " + option.lastName;
              }}
              filterOptions={(x) => x}
              onInputChange={handleNameSearch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    isEdit ? getPatientName() : "Enter the patient Id"
                  }
                />
              )}
            />
          </div>

          <div
            className={`appointment-dialog-main-section-2 appointment-dialog-main-section-vendor   ${
              isEdit ? "disabled-section-class" : ""
            }`}
          >
            <p>Type</p>
            <Select
              value={type}
              onChange={(e) => {
                getMinMaxDates(e.target.value);
                getCalendarList(e.target.value);
                setType(e.target.value);
                let service = serviceList.find(
                  (serv) => serv.ID == e.target.value
                );

                setCurrentService(service);
                settimeSlot(null);

                if (service && service.addressRequired) {
                  setAddRequired(true);
                } else {
                  setAddRequired(false);
                }
              }}
              disabled={props.isEdit}
              className="appointment-dialog-section-2-select navigo-dashboard-select-css"
            >
              {!isEdit &&
                serviceList.map((service, index) => {
                  return <MenuItem value={service.ID}>{service.name}</MenuItem>;
                })}
              {isEdit &&
                props.services.map((service, index) => {
                  return <MenuItem value={service.ID}>{service.name}</MenuItem>;
                })}
            </Select>
          </div>
          {!props.isEdit &&
            currentService.isLabTest &&
            patient.current &&
            !patient.current.email && (
              <div className="general-navigo-anchor-tag">
                <a
                  href={`/patients/${patient.current.id}/new-bridge`}
                  target="_blank"
                >
                  + Add email
                </a>
              </div>
            )}
          {/* {currentService.isLabTest && (
            <div
              className={`appointment-dialog-main-section-2  ${
                isEdit ? "disabled-section-class" : ""
              }`}
            >
              <p>Choose Lab preference</p>
              <Select
                value={labSelected}
                onChange={(e) => {
                  setLabSelected(e.target.value);
                }}
                disabled={props.isEdit}
                className="appointment-dialog-section-2-select"
              >
                <MenuItem value={1}>{"RedCliffe"}</MenuItem>
                <MenuItem value={2} disabled={true}>
                  {"Thyrocare"}
                </MenuItem>
              </Select>
            </div>
          )} */}
          {(props.isEdit
            ? showEditAddress
            : currentService && currentService.addressRequired) && (
            <div
              className={`appointment-dialog-main-section-1 common-section-input-class ${
                isEdit ? "disabled-section-class" : ""
              }`}
            >
              <div className="appointment-dialog-main-section-1-address">
                <p>Address</p>
                {patient.current && patient.current.id && (
                  <a
                    href={`/patients/${patient.current.id}/new-bridge?address=true`}
                    target="_blank"
                  >
                    + Add address
                  </a>
                )}
              </div>
              <Autocomplete
                id="appointment-dialog-name"
                className="appointment-dialog-main-section-1-name"
                options={addressList}
                disabled={isEdit}
                onChange={(e, v) => {
                  if (v) {
                    setAddressSelected(v);
                    setAddRequired(false);
                  } else {
                    setAddressSelected({});
                    setAddRequired(true);
                  }
                }}
                getOptionLabel={(option) => {
                  return getPatientAddress(option);
                }}
                noOptionsText={
                  <div className="no-address-found-autocomplete-node">
                    <p>No address found</p>
                  </div>
                }
                onOpen={() => {
                  handleGetAddress(patient.current.id);
                }}
                filterOptions={(x) => x}
                // onInputChange={handleNameSearch}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={
                      isEdit
                        ? getPatientAddress(editAddress)
                        : "Choose an address"
                    }
                  />
                )}
              />
            </div>
          )}

          <div
            className={`appointment-dialog-main-section-4 common-section-input-class ${
              isEdit
                ? "disabled-section-class disabled-section-class-email-cc"
                : ""
            }`}
          >
            <p>Client code</p>
            <input
              type="text"
              value={clientCode}
              placeholder="Enter the client code"
              onChange={(e) => {
                setClientCode(e.target.value);
              }}
              // disabled={isEdit}
              disabled={true}
            />
          </div>
          <div
            className={`appointment-dialog-main-section-1 common-section-input-class`}
          >
            <p>Assigned to:</p>
            <Autocomplete
              id="appointment-dialog-name"
              className="appointment-dialog-main-section-1-name"
              options={assignedToList}
              disabled={disableAssignedTo}
              value={assignedTo}
              onChange={(e, v) => {
                let newEmail = "";
                if (v) {
                  newEmail = v;
                }
                setAssignedTo(newEmail);
                if (props.isEdit) {
                  setEditAppointDate(null);
                  setEditTimeSlot(null);
                } else {
                  setAppointDate(null);
                  settimeSlot(null);
                }
              }}
              closeIcon={null}
              getOptionLabel={(option) => {
                return option;
              }}
              // filterOptions={(x) => x}
              // onInputChange={handleNameSearch}
              renderInput={(params) => (
                <TextField {...params} placeholder={"Enter the email Id"} />
              )}
            />
          </div>
          <div className="appointment-dialog-main-section-5">
            <div className="appointment-dialog-main-section-5-left">
              <p>Date</p>
              <div className="appointment-dialog-main-section-5-left-date">
                <FontIcon>date_range</FontIcon>
                <DatePicker
                  value={appointDate}
                  onChange={(e) => {
                    setAppointDate(e);
                    setFetchingSlot(true);
                    getTimeSlots(e);
                  }}
                  className="appointment-dialog-main-section-5-left-datepicker"
                  format="DD/MM/YYYY"
                  placeholder="Choose a date"
                  disabled={
                    type === "" ||
                    isEdit ||
                    (currentService.isLabTest && !addressSelected.id)
                  }
                  minDate={minDate.current}
                  maxDate={maxDate.current}
                />
              </div>
            </div>
            <div className="appointment-dialog-main-section-5-right">
              <p>Timeslot</p>
              {!fetchingSlot ? (
                <Autocomplete
                  id="appointment-dialog-time-slot"
                  options={slots}
                  disabled={noSlots || isEdit}
                  value={timeslot}
                  onChange={handleTimeSlotChange}
                  getOptionLabel={(option) => {
                    return option.slot;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={`${
                        isEdit
                          ? timeslot
                          : noSlots
                          ? "No slot available"
                          : "Choose a timeslot"
                      }`}
                    />
                  )}
                />
              ) : (
                <div className="fetching-time-slot-container">
                  <p>Fetching slots</p>
                  <div className="animated-dots-parent">
                    <div className="animated-dots animated-delay-0 "></div>
                    <div className="animated-dots animated-delay-1 "></div>
                    <div className="animated-dots animated-delay-2 "></div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {props.isEdit && (
            <p className="appointment-dialog-main-section-5-edit-heading">
              New schedule
            </p>
          )}
          {props.isEdit && (
            <div className="appointment-dialog-main-section-5">
              <div className="appointment-dialog-main-section-5-left">
                <p>Date</p>
                <div className="appointment-dialog-main-section-5-left-date">
                  <FontIcon>date_range</FontIcon>

                  <DatePicker
                    value={editappointDate}
                    onChange={(e) => {
                      setEditAppointDate(e);
                      setFetchingSlotEdit(true);
                      getTimeSlots(e);
                    }}
                    className="appointment-dialog-main-section-5-left-datepicker"
                    format="DD/MM/YYYY"
                    placeholder="Choose a date"
                    minDate={new Date()}
                    maxDate={
                      new Date(
                        moment(new Date()).add(
                          props.currentAppointment.service.maxDayBooking,
                          "days"
                        )
                      )
                    }
                  />
                </div>
              </div>
              <div className="appointment-dialog-main-section-5-right">
                <p>Timeslot</p>
                {!fetchingSlotEdit ? (
                  <Autocomplete
                    id="appointment-dialog-time-slot"
                    options={slots}
                    disabled={noSlots}
                    onChange={(e, v) => {
                      if (v) {
                        setEditTimeSlot(v);
                      }
                    }}
                    value={editTimeSlot}
                    getOptionLabel={(option) => {
                      return option.slot;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder={`${
                          noSlots ? "No slot available" : "Choose a timeslot"
                        }`}
                      />
                    )}
                  />
                ) : (
                  <div className="fetching-time-slot-container">
                    <p>Fetching slots</p>
                    <div className="animated-dots-parent">
                      <div className="animated-dots animated-delay-0 "></div>
                      <div className="animated-dots animated-delay-1 "></div>
                      <div className="animated-dots animated-delay-2 "></div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!props.isEdit && (
            <button
              className="appointment-dialog-main-submit-button"
              disabled={
                !timeslot ||
                appointDate.length === 0 ||
                type === "" ||
                patient.current == null ||
                loading ||
                addRequired
              }
              onClick={handleCreateAppointmentClick}
            >
              {"Create appointment"}
            </button>
          )}
          {props.isEdit && (
            <button
              className="appointment-dialog-main-submit-button"
              disabled={
                !editTimeSlot || editappointDate.length === 0 || loading
              }
              onClick={() => {
                // if (currentService.isLabTest) {
                //   handleLabtestAppointmentUpdate();
                // } else {
                handleAppointmentUpdate();
                // }
              }}
            >
              {"Edit appointment"}
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default AppointMentDialog;
