import AuthenticationService from "service/auth/AuthenticationService";
import EnvironmentService from "service/environment/EnvironmentService";
import APIConfig from "service/network/APIConfig";
import APIService from "service/network/APIService";

class UserService {
  constructor() {
    this.authenticationService = new AuthenticationService();
    this.environmentService = new EnvironmentService();
    this.apiService = new APIService();
  }

  getOTP(cc, phoneNo, tenantId, onResponse, onError) {
    let url =
      APIConfig.user.getOTPNew +
      "?phoneno=" +
      phoneNo +
      "&countrycode=" +
      cc +
      "&tenantid=" +
      tenantId;

    return this.apiService.get(url, onResponse, onError);
  }

  verifyOTP(countrycode, phoneNo, otp, tenantId, onResponse, onError) {
    let url = APIConfig.user.verifyOTPNew;
    let data = {
      countrycode: parseInt(countrycode),
      phoneno: parseInt(phoneNo),
      otp: parseInt(otp),
      tenantid: parseInt(tenantId),
    };

    return this.apiService.post(url, data, onResponse, onError);
  }
  getDoctorOTP(phoneNo, onResponse, onError) {
    let url = APIConfig.user.getDoctorOTP + "?phoneno=" + phoneNo;

    return this.apiService.get(url, onResponse, onError);
  }

  verifyDoctorOTP(phoneNo, otp, onResponse, onError) {
    let url = APIConfig.user.verifyDoctorOTP;
    let data = {
      phoneno: parseInt(phoneNo),
      otp: parseInt(otp),
    };

    return this.apiService.post(url, data, onResponse, onError);
  }

  new(employee, onResponse, onError) {
    let url = APIConfig.user.new;

    return this.apiService.post(url, employee, onResponse, onError);
  }

  getAll(onResponse, onError) {
    let url = APIConfig.user.getAll;

    return this.apiService.get(url, onResponse, onError);
  }

  getEmployeeInfo(employeeId, onResponse, onError) {
    let url = APIConfig.user.get.format(employeeId);

    return this.apiService.get(url, onResponse, onError);
  }

  isDoctor() {
    let isDoc = false;
    const user = this.authenticationService.getUserProfile();
    if (
      user &&
      user.doctorProfile &&
      user.doctorProfile.id > 0 &&
      !user.employeeProfile
    ) {
      isDoc = true;
    }
    return isDoc;
  }
  isEmployee() {
    let isEmp = false;
    const user = this.authenticationService.getUserProfile();
    if (user && user.employeeProfile && user.employeeProfile.id > 0) {
      isEmp = true;
    }
    return isEmp;
  }
  searchDoctorCode(code, onSuccess, onError) {
    const url = APIConfig.user.verifyDocCode.format(code);
    this.apiService.get(url, onSuccess, onError);
  }
}

export default UserService;
