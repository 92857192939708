import React from "react";
import FontIcon from "react-md/lib/FontIcons";
import proto from "../assets/icons/1CCopy.png";
import flash from "../assets/icons/flash-1.png";
import patientProgram from "../assets/icons/patient-program-icon.png";
export const doctorNavItems = [
  {
    key: "/",
    primaryText: "Patients",
    leftIcon: <FontIcon>people</FontIcon>,
    active: true,
  },
];
export const navItems = [
  {
    key: "/",
    primaryText: "Home",
    leftIcon: <FontIcon id="home">home</FontIcon>,
    active: true,
  },
  {
    key: "/doctors",
    primaryText: "Doctors",
    leftIcon: <FontIcon id="doctor_navigation">inbox</FontIcon>,
    active: false,
  },
  {
    key: "/patients",
    primaryText: "Patients",
    leftIcon: <FontIcon id="patient_navigation">people</FontIcon>,
  },
  {
    key: "bd-automation",
    primaryText: "Plans",
    leftIcon: (
      <FontIcon>
        <img src={flash} width="20rem" height="20rem" alt="Logo" />
      </FontIcon>
    ),
  },
  {
    key: "/patient-program",
    primaryText: "Patients",
    leftIcon: (
      <FontIcon id="patient_navigation">
        <img src={patientProgram} width="20rem" height="20rem" alt="Logo" />
      </FontIcon>
    ),
  },
  {
    key: "/sales",
    primaryText: "Sales",
    leftIcon: <FontIcon id="sales_navigation">filter_list</FontIcon>,
  },
  {
    key: "/feedbacks",
    primaryText: "Feedbacks",
    leftIcon: <FontIcon id="feedback_navigation">drafts</FontIcon>,
  },
  { key: "divider", divider: true },
  {
    key: "/communications",
    primaryText: "Communications",
    leftIcon: <FontIcon id="communication_navigation">mail</FontIcon>,
  },
  {
    key: "https://services.prod.zyla.in/care-manager/",
    primaryText: "Care Manager",
    leftIcon: <FontIcon id="cm_navigation">sentiment_satisfied</FontIcon>,
  },
  // {
  //   key: "care-manager-sales",
  //   primaryText: "Sales",
  //   leftIcon: <FontIcon id="salescm_navigation">star</FontIcon>
  // },
  // {
  //   key: "/care-manager-az",
  //   primaryText: "Az-Cm",
  //   leftIcon: <FontIcon id="azcm_navigation">book</FontIcon>
  // },
  {
    key: "/medicine-orders",
    primaryText: "Medicine Orders",
    leftIcon: <FontIcon id="medicine_navigation">opacity</FontIcon>,
  },
  {
    key: "/coupon-generator",
    primaryText: "Coupon Generator",
    leftIcon: <FontIcon id="note">note</FontIcon>,
  },
  {
    key: "/information-cards",
    primaryText: "Info Cards",
    leftIcon: (
      <FontIcon id="infoCards_navigation">perm_device_information</FontIcon>
    ),
  },
  {
    key: "/miscellaneous",
    primaryText: "Miscellaneous",
    leftIcon: <FontIcon id="settings_navigation">settings</FontIcon>,
  },
  {
    key: "/escalations",
    primaryText: "Escalations",
    leftIcon: (
      <FontIcon id="escalation_navigation" className="tertiary">
        trending_up
      </FontIcon>
    ),
  },
  { key: "divider", divider: true },
  {
    key: "/games",
    primaryText: "Games",
    leftIcon: <FontIcon id="games_navigation">videogame_asset</FontIcon>,
  },
  {
    key: "/protocols",
    primaryText: "Protocols",
    leftIcon: (
      <FontIcon id="proto_navigation">
        <img src={proto} width="20rem" height="20rem"></img>
      </FontIcon>
    ),
  },
];
